import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ResumeSectionHeader from "../section-header";
import EditResumeInfo from "./edit-resume-info";
import { WebViewButton } from "../../../common/web-view-button";

function ResumeInfo({
  id,
  resume_name,
  summary,
  employee_id,
  summary_bullets,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Box mb={3}>
        <ResumeSectionHeader title="Resume">
          <WebViewButton resumeId={id}></WebViewButton>
          <Button
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => setIsModalOpen(true)}
          >
            edit
          </Button>
        </ResumeSectionHeader>
        <Box my={2} px={1}>
          <Box>
            <Box fontWeight="fontWeightMedium">Resume Name</Box>
            <Box>{resume_name}</Box>
          </Box>
          <Box mt={2}>
            <Box fontWeight="fontWeightMedium">Summary</Box>
            <Box>{summary}</Box>
          </Box>
        </Box>
      </Box>
      <EditResumeInfo
        open={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        title="Edit Resume"
        data={{
          id,
          resume_name,
          summary,
          employee_id,
          summary_bullets,
        }}
      />
    </>
  );
}

export default ResumeInfo;
