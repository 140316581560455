import React from "react";
import { Box, Chip, Grid, Paper } from "@material-ui/core";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";

import { ExportButton } from "../common/export-button";
import { WebViewButton } from '../common/web-view-button';

function ResumeResultList({ employees, searchParams }) {
  const classes = useStyles();

  const renderEmployee = (employee) => (
    <Paper className={classes.paper} key={employee.employeeid}>
      <div className={classes.header}>
        <Box mx={1}>{employee.firstname} {employee.lastname}</Box>
      </div>

      <div className={classes.body}>
        {employee.resumes.map((resume, index) => renderResume(resume, index === employee.resumes.length - 1))}
      </div>
    </Paper>
  );

  const renderResume = (resume, isLast) => (
    <Box mb={isLast ? 0 : 3} key={resume.id}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item className={classes.resumeName}>
          {resume.resume_name}
        </Grid>
        <Grid item className={classes.noShrink}>
          <Box px={4}>{dayjs(resume.update_date).format("MM/DD/YYYY")}</Box>
        </Grid>
        <Grid item className={classes.noShrink}>
          <Box mx={1}>
            <WebViewButton resumeId={resume.id} />
          </Box>
        </Grid>
        <Grid item className={classes.noShrink} >
          <ExportButton resume={resume} />
        </Grid>

      </Grid>

      {resume.resume_skills?.length > 0 && renderSkills(resume.resume_skills)}
      {resume.experiences?.length > 0 && renderExperiences(resume.experiences)}

      {resume.summary}
    </Box>
  );

  const renderSkills = (skills) => (
    <Box className={classes.chipBox}>
      {skills.map((rs) => (
        <Chip
          color="primary"
          key={rs.skill.id}
          label={rs.skill.label}
        />
        ))}
    </Box>
  );

  const renderExperiences = (experiences) => {
    // Array to store the matching tools
    const matchingTools = [];
    const matchingExperiences = [];
    const matchingCompanies = [];
    const searchedToolsUsed = searchParams.toolsUsed
    const searchedExperience = searchParams.experience
    const searchedCompanies = searchParams.companies


    experiences.forEach((experience) => {
      if (experience.company && searchedCompanies) {
        // Iterate through each searched company
        searchedCompanies.forEach((searchedCompany) => {
          const lowerCasedCompany = experience.company.toLowerCase(); // Convert the company name to lowercase
          const lowerCasedSearchedCompany = searchedCompany.trim().toLowerCase(); // Convert the searched company name to lowercase

          // Check if the lowercase company name includes the lowercase searched company name
          // and if the matching companies array does not already include the lowercase company name
          if (lowerCasedCompany.includes(lowerCasedSearchedCompany) && !matchingCompanies.includes(searchedCompany)) {
            matchingCompanies.push(searchedCompany); // Add the original company name to the matching companies array
          }
        });
      }
      if (experience.experience_bullets && searchedExperience) {
        const lowerCasedSearchedExperience = searchedExperience.trim().toLowerCase(); // Convert the searched experience text to lowercase

        // Iterate through each experience bullet
        experience.experience_bullets.forEach((experienceBullet) => {
          const bulletText = experienceBullet.bullet_text; // Extract the bullet text from the experience bullet
          const lowerCaseBulletText = bulletText.toLowerCase(); // Convert the bullet text to lowercase

          // Check if the lowercase bullet text includes the lowercase searched experience text
          // and if the matching experiences array does not already include the searched experience
          if (lowerCaseBulletText.includes(lowerCasedSearchedExperience) && !matchingExperiences.includes(searchedExperience)) {
            matchingExperiences.push(searchedExperience); // Add the searched experience to the matching experiences array
          }
        });
      }
      if(experience.tech_tools_bullet && searchedToolsUsed) {
        // Split the tech_tools_bullet string into an array and trim each tool
        const toolsUsedArr = experience.tech_tools_bullet.split(',').map((tool) => tool.trim());

        // Iterate over each tool used
        toolsUsedArr.forEach((tool) => {
          // Iterate over each searched tool
          searchedToolsUsed.forEach((searchedTool) => {
            // Convert both tools to lowercase for case-insensitive comparison
            const lowerCasedTool = tool.toLowerCase();
            const lowerCasedSearchedTool = searchedTool.trim().toLowerCase();

            // Check if the tool includes the searched tool and add it to the matchingTools array if not already included
            if (lowerCasedTool.includes(lowerCasedSearchedTool) && !matchingTools.includes(tool)) {
              matchingTools.push(tool);
            }
          });
        });
      }
    });

    // Render the list of matching tools as chips
    return (
        <Box className={classes.chipBox}>
          {matchingTools.map((tool) => (
              <Chip color="secondary" key={tool} label={tool} />
          ))}
          {matchingExperiences.map((experience) => (
              <Chip key={experience} label={experience} />
          ))}
          {matchingCompanies.map((company) => (
              <Chip color="primary" key={company} label={company} />
          ))}
        </Box>
    );
  };

  return (
    <>
      {employees?.map((employee) => renderEmployee(employee))}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  body: {
    padding: theme.spacing(1),
  },
  resumeName: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    flexGrow: 1
  },
  noShrink: {
    flex: 0
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
  chipBox: {
    fontWeight: 500,

    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default ResumeResultList;
