import React, { useContext, useEffect } from "react";

import awsconfig from "./aws-exports";
import "typeface-roboto";
import Navbar from "./components/navbar";
import TabContainer from "./components/tab-container";
import { Notifications, useNotifications } from "./components/common/notifications";
import { ResumeContext } from "./context/resume-context";
import { ClientContext } from "graphql-hooks";

import { API, Auth, Hub } from "aws-amplify";
import Login from "./components/login/login";
import { Route, Switch } from 'react-router-dom';
import { ResumeNoEdit } from './components/my-resumes/resume-no-edit';

API.configure(awsconfig);

function App() {
  const gqlClient = useContext(ClientContext);
  const { isLoggedIn, updateUser, updateIsLoggedIn } = useContext(ResumeContext);
  const notifications = useNotifications();

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          gqlClient.setHeader(
            "Authorization",
            `Bearer ${data.signInUserSession.idToken.jwtToken}`
          );
          updateUser(data);
          updateIsLoggedIn(true);
          break;
        case "signOut":
          gqlClient.setHeader("Authorization", null);
          updateIsLoggedIn(false);
          updateUser("");
          break;
        default:
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        gqlClient.setHeader(
          "Authorization",
          `Bearer ${user.signInUserSession.idToken.jwtToken}`
        );
        updateUser(user);
        updateIsLoggedIn(true);
      })
      .catch((error) =>
        notifications.error('An error occurred during login', (
          JSON.stringify(error, null, 2 )
        ))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoggedIn ? (
        <Switch>
          <Route path="/resume/:id" component={ResumeNoEdit} />
          <Route>
            <Navbar />
            <TabContainer />
          </Route>
        </Switch>
      ) : (
        <Login />
      )}
      <Notifications />
    </>
  );
}

export default App;
