import React, { useContext } from "react";
import PropTypes from "prop-types";
import { getSnackbarProps, NotificationContext } from "../../context/notification-context";
import { IconButton, Snackbar } from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import { NotificationDetails } from "./notification-details";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import notificationConsts from '../../constants/notification-consts';

const useStyles = makeStyles((theme) => ({
  alert: {
    "& .MuiAlert-icon": {
      "align-items": "center"
    },
    "& .notification-content + *": {
      marginLeft: theme.spacing(4)
    }
  }
}));

export function Notifications({ max }) {
  const notificationContext = useContext(NotificationContext);
  const classes = useStyles();

  function handleRemove(notificationId) {
    notificationContext.dequeue(notificationId);
  }

  function handleClose(notificationId) {
    notificationContext.close(notificationId);
  }

  function handlePause(notificationId) {
    notificationContext.pauseAutoHide(notificationId);
  }

  function handleResume(notificationId) {
    notificationContext.resumeAutoHide(notificationId);
  }

  return (
    <>
      {notificationContext.notificationQueue.slice(0, max).map(({ notificationId, action, pauseAutoHide, type, ...notificationProps }) => {
        const remove = () => {
          if (!notificationProps.open) {
            handleRemove(notificationId);
          }
          return notificationProps.onExited?.();
        };
        const close = (event, reason) => {
          if (!reason || reason !== 'clickaway') {
            handleClose(notificationId);
          }
        };
        const pause = () => handlePause(notificationId);
        const resume = () => handleResume(notificationId);

        const complexAction = typeof action === 'function';
        const notificationAction = complexAction ? action(close, pause, resume) : action;

        return (
          <Snackbar
            key={notificationId}
            children={type !== null && (
              <Alert
                className={classes.alert}
                elevation={6}
                onClose={complexAction ? null : close}
                severity={type}
                variant="filled"
              >
                <span className="notification-content">{notificationProps.message}</span>
                {notificationAction}
              </Alert>
            )}
            {...getSnackbarProps(notificationProps)}
            action={notificationAction}
            autoHideDuration={pauseAutoHide ? null : notificationProps.autoHideDuration}
            onClose={close}
            onExited={remove} />
        );
      })}
    </>
  );
}

Notifications.propTypes = {
  max: PropTypes.number
};

Notifications.defaultProps = {
  max: 1
};

const notificationDefaults = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  autoHideDuration: 6000
};

export function useNotifications() {
  const notificationContext = useContext(NotificationContext);

  function notificationFromType(type) {
    return (message, details = null, snackbarProps = {}) => {
      notificationContext.enqueue({
        ...notificationDefaults,
        message: message,
        action: details ? (close, pause, resume) => (
            <>
              <NotificationDetails content={details} type={type}
                                   notificationControls={{close, pause, resume}}/>
              <IconButton size="small" title="Close" aria-label="close" color="inherit" onClick={close}>
                <CloseIcon fontSize="small"/>
              </IconButton>
            </>
        ) : null,
        ...snackbarProps,
        open: true,
        type
      });
    }
  }

  const warning = notificationFromType(notificationConsts.WARNING);
  const error = notificationFromType(notificationConsts.ERROR);
  const info = notificationFromType(notificationConsts.INFO);
  const success = notificationFromType(notificationConsts.SUCCESS);

  function remove(notificationId) {
    notificationContext.dequeue(notificationId);
  }

  return {
    error,
    remove,
    warning,
    info,
    success
  };
}
