import { Box, Breadcrumbs, Grid, Link } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { useManualQuery, useQuery } from "graphql-hooks";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import SearchFilterPanel from "../common/search-filter-panel";
import {
  ALL_SKILLS,
  ALL_TOOLS_USED,
  ALL_COMPANIES,
  ALL_LOCATIONS,
  getResumeSearchVariables,
  RESUME_SEARCH,
  ALL_ENTERPRISES
} from "../../graphql/query";
import Header from "../common/header";
import LoaderOverlay from "../common/loader-overlay";
import ResumeResultList from "./resume-result-list";
import { debounce } from "lodash";
import { withoutEmptyProps, trimStrings, sortSkills, employeesNoEndDate} from "../../utils/object-utils";

const PAGE_SIZE = 10;

function ImprovingResumes() {
  const classes = useStyles();
  const [resumeResults, setResumeResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: "",
    experience: "",
    enterprises: [],
    locations: [],
    skills: [],
    limit: PAGE_SIZE,
    offset: 0,
  });

  const { data: skillData, loading: skillLoading } = useQuery(ALL_SKILLS);

  const { data: toolsUsedData, loading: toolLoading } = useQuery(ALL_TOOLS_USED);

  const { data: companiesData, loading: companiesLoading } = useQuery(ALL_COMPANIES);

  const { data: enterpriseData, loading: enterprisesLoading } = useQuery(
      ALL_ENTERPRISES
  );

  const { data: locationsData, loading: locationsLoading } = useQuery(
    ALL_LOCATIONS
  );
  const [fetchResumes] = useManualQuery(RESUME_SEARCH);

  const hasResults = !!resumeResults?.employee?.length;
  const isInitialLoadComplete =
    (!loading || hasResults) && !skillLoading && !toolLoading && !companiesLoading && !locationsLoading && !enterprisesLoading;

  const pageCount = Math.ceil(
    resumeResults?.employee_aggregate?.aggregate.count / PAGE_SIZE || 0
  );
  const page = searchParams.offset / PAGE_SIZE + 1;

  const setSearchParamsDebounced = useMemo(
    () =>
      debounce((value) => {
        setSearchParams(withoutEmptyProps(value));
      }, 500),
    []
  );

  const handleFilterChange = useCallback(
    (params) => {
      setSearchParamsDebounced({
        ...searchParams,
        ...params,
        offset: 0,
      });
    },
    [searchParams, setSearchParamsDebounced]
  );

  const handlePageChange = useCallback(
    (event, newValue) => {
      window.scrollTo(0, 0);
      setSearchParamsDebounced({
        ...searchParams,
        offset: (newValue - 1) * PAGE_SIZE,
      });
    },
    [searchParams, setSearchParamsDebounced]
  );

  useEffect(() => {
    async function fetchSearchData() {
      const variables = getResumeSearchVariables(
        trimStrings({
          ...searchParams,
        })
      );
      setError(false);
      setLoading(true);

      let result = null;
      try {
        result = await fetchResumes({ variables });
      } catch (error) {
        setError(error);
      }

      if (result) {
        let noEndDate = result.data?.employee  !== undefined ?  employeesNoEndDate(result.data) : result.data;
        let sortedSkills = searchParams.skills?.length > 1 ? sortSkills(noEndDate) : noEndDate;
        setResumeResults(sortedSkills);
      }
      setLoading(false);
    }
    fetchSearchData();
  }, [fetchResumes, searchParams, setError, setLoading, setResumeResults]);

  return (
    <Box className={classes.root}>
      <Header>
        <Breadcrumbs className={classes.breadcrumb} separator=">">
          <Link onClick={() => {}}>SEARCH RESUMES</Link>
        </Breadcrumbs>
      </Header>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <SearchFilterPanel
            searchLabel="Name"
            experienceSearchLabel="Keyword"
            enterprises={enterpriseData?.enterprise || [] }
            locations={locationsData?.enterprise_manager || []}
            skills={skillData?.skill || []}
            toolsUsed={Array.from(new Set(toolsUsedData?.experience?.flatMap((experience) => {
              // Split tech tools string into an array of strings, trim leading/trailing whitespace, remove & characters and blank spaces
              const techTools = experience.tech_tools_bullet.split(",");
              return techTools.map((tool) => tool.trim().replace(/^\s+|\s+$/g, '').replace(/&/g, ''));
            }) || [])).sort()} // Create a set of unique tech tools, convert back to array, and sort alphabetically
            companies={Array.from(new Set(companiesData?.experience?.map((experience) => {
              return experience.company;
            }) || [])).sort()}
            params={searchParams}
            showSkills
            showToolsUsed
            showCompanies
            showLocations
            showEnterprises
            onParamsChange={handleFilterChange}
          />
        </Grid>
        <Grid item xs={12} lg={9} className={classes.resultsGrid}>
          {error && <div>Error: {JSON.stringify(error)}</div>}
          {isInitialLoadComplete && !error && !hasResults && (
            <div className={classes.info}>
              <h3>No Results Found</h3>
            </div>
          )}
          {hasResults && (
            <>
              <ResumeResultList employees={resumeResults.employee} searchParams={searchParams} />
              <Pagination
                count={pageCount}
                page={page}
                showFirstButton
                showLastButton
                onChange={handlePageChange}
                className={classes.pagination}
                classes={{
                  ul: classes.paginationList,
                }}
              />
            </>
          )}
          <LoaderOverlay
            showSpinner={!isInitialLoadComplete || loading}
            showOverlay={isInitialLoadComplete && loading}
            color="gray" />
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  breadcrumb: {
    margin: "6px 8px",
  },
  info: {
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    textAlign: "center",
  },
  resultsGrid: {
    position: "relative",
  },
  pagination: {
    marginTop: theme.spacing(3),
  },
  paginationList: {
    justifyContent: "flex-end",
  },
}));

export default ImprovingResumes;
