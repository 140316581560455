import React, { useContext, useEffect } from "react";
import { Box, Button, CircularProgress, Grid, Paper } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { ExportButton } from "../common/export-button";
import { useManualQuery, useMutation, useQuery } from "graphql-hooks";
import dayjs from "dayjs";

import { ResumeContext } from "../../context/resume-context";
import {
  DELETE_RESUME,
  INSERT_RESUME,
  RESUMES_BY_USER,
  RESUME_BY_ID_SANS_ID,
  CREATE_EXPERIENCES,
  CREATE_RESUME_SKILLS,
} from "../../graphql/query";
import { WebViewButton } from '../common/web-view-button';

function ResumeList({ readonly = false }) {
  const classes = useStyles();
  const {
    updateResume,
    user,
    listRefreshNeeded,
    updateResumeList,
  } = useContext(ResumeContext);
  const employeeId = user.signInUserSession.idToken.payload.dbsub;
  const { loading, data, error, refetch } = useQuery(RESUMES_BY_USER, {
    variables: {
      employeeId,
    },
  });
  const [getResumeById] = useManualQuery(RESUME_BY_ID_SANS_ID);
  const [insertResumeInfo] = useMutation(INSERT_RESUME);
  const [insertExperiences] = useMutation(CREATE_EXPERIENCES);
  const [insertResumeSkills] = useMutation(CREATE_RESUME_SKILLS);


  useEffect(() => {
    async function refetchResumeListData() {
      await refetch();
    }
    if (listRefreshNeeded) {
      refetchResumeListData();
      updateResumeList(false);
    }
  }, [listRefreshNeeded, updateResumeList, refetch]);

  const [deleteResumeById] = useManualQuery(DELETE_RESUME);

  const selectResume = (resume) => {
    updateResume(resume);
  };

  const deleteResume = async (resumeId) => {
    await deleteResumeById({ variables: { resumeId } });
    updateResumeList(true);
  };

  const copyResume = async (resumeId) => {
    const {
      data: { resume_by_pk: currentResume }
    } = await getResumeById({
      variables: {
        resumeId: `${resumeId}`,
      },
    });
  
    const {
      data: { insert_resume_one: insertedResume },
    } = await insertResumeInfo({
      variables: {
        resume: {
          summary: currentResume.summary,
          resume_name: `Copy of ${currentResume.resume_name}`,
          employee_id: employeeId,
          summary_bullets: {
            data: currentResume.summary_bullets,
          },
          education: {
            data: currentResume.education,
          },
          certification_trainings: {
            data: currentResume.certification_trainings,
          },
        },
      },
    });

    // Insert experience and skills seperately because we need to know the resume id of the new copy first.
    const newId = insertedResume.id;
    const experiences = currentResume.experiences.map((experience) => {
      return {
        resume_id: newId,
        ...experience,
        ...{
          experiences: {
            data: experience?.experiences?.map((innerExperience) => {
              const { employer_id, ...innerExperienceCloned } = innerExperience;
              return {
                ...innerExperienceCloned,
                ...{
                  experience_bullets: {
                    data: innerExperience.experience_bullets,
                  },
                  resume_id: newId
                },
              };
            }),
          },
          experience_bullets: {
            data: experience.experience_bullets,
          },
        },
      };
    });

    let resumeSkills = [];
    currentResume.resume_categories.forEach(category => {
      category.resume_category_skills.forEach(skill => resumeSkills.push({
        skill_id: skill.skill_id, 
        sort_order: skill.sort_order, 
        resume_id: newId }));
    });

    await insertExperiences({variables: {
      experiences
    }});
    await insertResumeSkills({variables: {
        resumeSkills
    }});
    
    updateResumeList(true);
  };

  return (
    <Box mr={1}>
      <Grid container spacing={3}>
        {loading && <CircularProgress />}
        {error && <div>`${JSON.stringify(error)}`</div>}
        {/* ------------- RESUME CARD ---------------*/}
        {data &&
          data.resume.map((resume) => (
            <Grid item xs={12} key={resume.id}>
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {readonly ? (
                      <Box className={classes.header}>
                        <Box mx={1}>
                          x
                          <ExportButton resume={resume} />
                        </Box>
                      </Box>
                    ) : (
                      <Box className={classes.header}>
                        <Box mx={1}>
                          <WebViewButton resumeId={resume.id} />
                        </Box>
                        <Box mx={1}>
                          <Button
                            color="primary"
                            onClick={async (e) => {
                              e.stopPropagation();
                              await copyResume(resume.id);
                            }}
                            startIcon={<FileCopyOutlinedIcon />}
                          >
                            copy
                          </Button>
                        </Box>
                        <Box mx={1}>
                          <ExportButton resume={resume} />
                        </Box>
                        <Box ml={1}>
                          <Button
                            color="primary"
                            startIcon={<DeleteOutlineIcon />}
                            onClick={async (e) => {
                              e.stopPropagation();
                              await deleteResume(resume.id);
                            }}
                          >
                            delete
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} onClick={() => selectResume(resume)}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <Box pl={1} color="primary.main" fontWeight={500}>
                          {resume.resume_name}
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                          Last Updated: {dayjs(resume.update_date).format("MM/DD/YYYY")}
                      </Grid>
                      <Grid item xs={8}>
                        <Box pr={1} pb={2}>
                          {resume.summary}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),

    "&:hover": {
      boxShadow: `0 2px 8px ${alpha(theme.palette.primary.main, 0.4)}`,
      cursor: "pointer",
    },
    "& $header:hover": {
      cursor: "default",
    },
  },
}));

export default ResumeList;
