import React from "react";
import { DialogContentText, Grid } from "@material-ui/core";
import ResumeModal from "./modal";

export function ConfirmDeleteModal({ title, open, onConfirmDelete, onClose }) {
  return (
    <ResumeModal
      title={title}
      open={open}
      handleSubmit={onConfirmDelete}
      handleClose={onClose}
      actionButtonName="delete"
      fullWidth={false}
    >
      <Grid container justifyContent="center">
        <Grid item>
          <DialogContentText>
            Are you sure you want to delete this? This action cannot be undone.
          </DialogContentText>
        </Grid>
      </Grid>
    </ResumeModal>
  );
}
