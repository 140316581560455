import React, { useContext, useState } from "react";
import { Box, Grid, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ResumeSectionHeader from "../section-header";
import { EditEducation } from "./edit-education";
import { ConfirmDeleteModal } from "../../../common/confirm-delete-modal";
import { useMutation } from "graphql-hooks";
import { DELETE_EDUCATION } from "../../../../graphql/query";
import { ResumeContext } from "../../../../context/resume-context";

function Education({ education }) {
  const classes = useStyles();
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [deletedEducation, setDeletedEducation] = useState(null);

  const [deleteEducation] = useMutation(DELETE_EDUCATION);
  const { updateRefreshNeeded } = useContext(ResumeContext);

  const handleConfirmDelete = async (education) => {
    try {
      await deleteEducation({
        variables: {
          education_id: education.id,
        },
      });
      updateRefreshNeeded(true);
      setDeletedEducation(null);
    } catch (error) {
      // TODO set error to global error context
      console.error(error);
    }
  };

  return (
    <Box mb={3}>
      <ResumeSectionHeader title="Education">
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setSelectedEducation({})}
        >
          add education
        </Button>
      </ResumeSectionHeader>
      <Box mt={1}>
        {education.map((ed) => (
          <Box key={ed.id} className={classes.educationContainer}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Box fontWeight="fontWeightMedium">{ed.school}</Box>
                <Box>{ed.degree}</Box>
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <Box>
                  {ed.graduated
                    ? `${ed.graduation_month}/${ed.graduation_year}`
                    : "current"}
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box className={classes.buttonBox}>
                  <IconButton
                    color="primary"
                    title="edit education"
                    onClick={() => setSelectedEducation(ed)}
                  >
                    <EditIcon className={classes.actionIcon} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    title="delete education"
                    onClick={() => setDeletedEducation(ed)}
                  >
                    <DeleteOutlineIcon className={classes.actionIcon} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
        {selectedEducation && (
          <EditEducation
            open={Boolean(selectedEducation)}
            education={selectedEducation}
            handleClose={() => setSelectedEducation(null)}
          />
        )}
        {deletedEducation && (
          <ConfirmDeleteModal
            open={Boolean(deletedEducation)}
            title={`Delete ${deletedEducation.school}?`}
            onConfirmDelete={() => handleConfirmDelete(deletedEducation)}
            onClose={() => setDeletedEducation(null)}
          />
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  educationContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    "&:hover": {
      background: theme.palette.grey[100],
    },
  },
  actionIcon: {
    fontSize: "1.2rem",
  },
  buttonBox: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));
export default Education;
