import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DayjsUtils from "@material-ui/pickers/adapter/dayjs";
import countryRegionData from "country-region-data";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useMutation } from "graphql-hooks";
import find from "lodash/find";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ResumeContext } from "../../../../context/resume-context";
import { CREATE_EDUCATION, UPDATE_EDUCATION } from "../../../../graphql/query";
import ResumeModal from "../../../common/modal";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 400,
    width: "100%",
  },
}));

const validationSchema = yup.object().shape({
  school: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  country: yup.string().required(),
  graduated: yup.boolean(),
  graduatedDate: yup.date().when("graduated", {
    is: true,
    then: yup.date().required("date is required").typeError("date is invalid"),
    otherwise: yup.date().typeError("date is invalid"),
  }),
  degree: yup.string().required(),
  major: yup.string(),
  minor: yup.string(),
  additional_desc: yup.string(),
});

dayjs.extend(utc);

export function EditEducation({ education, open, handleClose }) {
  const classes = useStyles();
  const { resume, updateRefreshNeeded } = useContext(ResumeContext);

  const [createEducation] = useMutation(CREATE_EDUCATION);
  const [updateEducation] = useMutation(UPDATE_EDUCATION);

  const defaultValues = education.id
    ? {
        ...education,
        graduatedDate: dayjs()
          .utc()
          .month(education.graduation_month - 1)
          .year(education.graduation_year)
          .startOf("month"),
      }
    : {
        graduatedDate: null,
        graduated: false,
        country: null,
      };

  const { control, errors, handleSubmit, register, reset, watch } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(education);
  }, [education, reset]);

  const hasGraduated = Boolean(watch("graduated"));
  const countryWatch = watch("country");
  const statesList = countryWatch
    ? find(countryRegionData, (cr) => cr.countryShortCode === countryWatch)
        .regions
    : [];

  const onSubmit = async (formData) => {
    const { graduatedDate, ...educationData } = formData;
    const graduatedDayJs = dayjs(graduatedDate);
    const updatedEducation = {
      ...educationData,
      resume_id: resume.id,
      graduation_month: formData.graduated ? graduatedDayJs.month() + 1 : null,
      graduation_year: formData.graduated ? graduatedDayJs.year() : null,
    };
    if (education.id) {
      await updateEducation({
        variables: {
          id: education.id,
          education: updatedEducation,
        },
      });
    } else {
      await createEducation({
        variables: {
          education: updatedEducation,
        },
      });
    }
    updateRefreshNeeded(true);
    handleClose();
  };

  return (
    <ResumeModal
      title={education.id ? `Edit ${education.school}` : `Create new education`}
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <LocalizationProvider dateAdapter={DayjsUtils}>
        <form>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                name="school"
                label="School"
                inputRef={register}
                variant="outlined"
                fullWidth
                required
                className={classes.textField}
                error={Boolean(errors.school)}
                helperText={errors?.school?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="city"
                label="City"
                inputRef={register}
                variant="outlined"
                fullWidth
                required
                className={classes.textField}
                error={Boolean(errors.city)}
                helperText={errors?.city?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                margin="normal"
                required
                error={Boolean(errors.country)}
                className={classes.formControl}
              >
                <InputLabel id="countryLabel">Country</InputLabel>
                <Controller
                  name="country"
                  control={control}
                  inputRef={register}
                  labelId="countryLabel"
                  label="Country"
                  autoWidth
                  defaultValue=""
                  as={
                    <Select autoWidth>
                      {countryRegionData.map((cr) => (
                        <MenuItem
                          key={cr.countryShortCode}
                          value={cr.countryShortCode}
                        >
                          {cr.countryName}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                <FormHelperText>{errors?.country?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                margin="normal"
                required
                error={Boolean(errors.state)}
                className={classes.formControl}
              >
                <InputLabel id="stateLabel">State/Province/Region</InputLabel>
                <Controller
                  name="state"
                  control={control}
                  inputRef={register}
                  labelId="stateLabel"
                  label="State/Province/Region"
                  defaultValue=""
                  as={
                    <Select autoWidth>
                      {statesList.map((state) => (
                        <MenuItem key={state.shortCode} value={state.shortCode}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                <FormHelperText>{errors?.state?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Controller
                    name="graduated"
                    control={control}
                    defaultValue={defaultValues.graduated}
                    render={({ onChange, value, ref, ...props }) => (
                      <Checkbox
                        {...props}
                        color="primary"
                        onChange={e => onChange(e.target.checked)}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                }
                label="Graduated?"
              />
            </Grid>
            {hasGraduated && (
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  required
                  error={Boolean(errors.graduatedDate)}
                >
                  <Controller
                    name="graduatedDate"
                    control={control}
                    inputRef={register}
                    defaultValue={defaultValues.graduatedDate}
                    as={
                      <DatePicker
                        variant="outlined"
                        autoOk
                        openTo="year"
                        views={["year", "month"]}
                        inputFormat="MM/YYYY"
                        mask="__/____"
                        label="Graduated Date"
                        onChange={() => {}}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            helperText="Month and year"
                          />
                        )}
                      />
                    }
                  />
                  <FormHelperText>
                    {errors?.graduatedDate?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name="degree"
                label="Degree"
                inputRef={register}
                variant="outlined"
                fullWidth
                required
                error={Boolean(errors.degree)}
                helperText={errors?.degree?.message}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="major"
                label="Major"
                inputRef={register}
                variant="outlined"
                fullWidth
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="minor"
                label="Minor"
                inputRef={register}
                variant="outlined"
                fullWidth
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="additional_desc"
                label="Additional Description"
                inputRef={register}
                variant="outlined"
                fullWidth
                className={classes.textField}
              />
            </Grid>
          </Grid>
        </form>
      </LocalizationProvider>
    </ResumeModal>
  );
}
