import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Auth } from "aws-amplify";
import reversedLogo from "../../images/improving-logo-white.svg";
import background from "../../images/login-background.png";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";

function Login() {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Box
        p={2}
        style={{
          position: "relative",
          top: 0,
          left: 0,
        }}
      >
        <img src={reversedLogo} alt="Improving logo" height="50" />
      </Box>
      <Grid
        container
        direction="column"
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "75vh" }}
      >
        <Grid item xs={12} md={6}>
          <Box pb={2}>
            <Card variant="outlined">
              <CardHeader
                title="sign in to your account"
                className={classes.cardHeader}
              />
              <CardContent>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => Auth.federatedSignIn({ provider: "improving-ad" })}
                >
                  sign in with improving sso
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles(() => ({
  paper: {
    background: `url(${background}) no-repeat center center fixed`,
    backgroundSize: "cover",
    height: "100vh",
    minHeight: "300px",
  },
  cardHeader: {
    textTransform: "uppercase",
  },
}));

export default Login;
