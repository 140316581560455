import { yupResolver } from "@hookform/resolvers/yup";
import {Button, Grid, TextField} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import WarningIcon from "@material-ui/icons/Warning";
import { Alert } from "@material-ui/lab";
import { useMutation } from "graphql-hooks";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useForm} from "react-hook-form";
import * as yup from "yup";
import { ResumeContext } from "../../context/resume-context";
import {
    DELETE_EMPLOYEE,
    INSERT_EMPLOYEE,
    UPDATE_DATE_HISTORY,
    UPDATE_EMPLOYEE,
} from "../../graphql/query";
import ControlledAutocomplete from "../common/controlled-autocomplete";
import LoaderOverlay from "../common/loader-overlay";
import ResumeModal from "../common/modal";
import dayjs from "dayjs";

const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    manager: yup.object().nullable().required(),
    middleinitial: yup.string(),
    position: yup.object().nullable().required(),
    role: yup.object().nullable().required()
});

function EditEmployeeInfo({
    data,
    managers,
    onClose,
    open,
    positions,
    roles,
    enterprises
}) {
    const classes = useStyles();
    const { user } = useContext(ResumeContext);
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [insertEmployee] = useMutation(INSERT_EMPLOYEE);
    const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);
    const [deleteEmployee] = useMutation(DELETE_EMPLOYEE);
    const [updateDateHistory] = useMutation(UPDATE_DATE_HISTORY);

    const isNew = !data;
    const isSelf = user && data && data.email === user.attributes?.email;
    const title = `${showDelete ? "Delete" : isNew ? "Add" : "Edit"} Employee`;

    const defaultValues = useMemo(
        () => ({
            email: data?.email || "",
            employeeid: data?.employeeid || "",
            firstname: data?.firstname || "",
            lastname: data?.lastname || "",
            begindate: data?.employee_date_histories?.length > 0 ? dayjs(data.employee_date_histories[0].begin_date).format('YYYY-MM-DD') : null,
            enddate: data?.employee_date_histories?.length > 0 ? dayjs(data.employee_date_histories[0].end_date).format('YYYY-MM-DD') : null,
            manager: managers.find(manager => manager.id === data?.manager_id) || null,
            middleinitial: data?.middleinitial || "",
            position: positions.find(option => option.id === data?.position_id) || null,
            role:
                (data?.employee_roles?.length &&
                    roles.find(
                        (option) => option.id === data.employee_roles[0].role_id
                    )) ||
                null,
        }),
        [managers, positions, roles, data]
    );

    const { control, register, handleSubmit, reset, errors } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    const onSubmit = useCallback(
        async (formData) => {
            let success;
            setIsLoading(true);

            if (showDelete) {
                const { data: deleteResult } = await deleteEmployee({
                    variables: {
                        employeeId: data.employeeid,
                    },
                });

                success = !!deleteResult?.delete_employee_by_pk?.employeeid;
            } else if (isNew) {const employee_date_histories = {data: []};
                if (formData.begindate !== '') {
                    employee_date_histories.data.push({begin_date: dayjs(formData.begindate).format('YYYY-MM-DD')});
                }
                if (formData.enddate !== '') {
                    employee_date_histories.data.push({end_date: dayjs(formData.enddate).format('YYYY-MM-DD')});
                }

                const { data: insertResult } = await insertEmployee({
                    variables: {
                        employee: {
                            email: formData.email,
                            firstname: formData.firstname,
                            lastname: formData.lastname,
                            location_id: enterprises.find(enterprise => enterprise.id === formData.manager.id).id,
                            manager_id: formData.manager.id,
                            middleinitial: formData.middleinitial,
                            position_id: formData.position.id,
                            employee_roles: {
                                data: [
                                    {
                                        employee_id: formData.employeeid,
                                        role_id: formData.role.id,
                                    },
                                ],
                            },
                            employee_date_histories: employee_date_histories,
                            resumes: {
                                data: [],
                            },
                        }
                    },
                });
                success = !!insertResult?.insert_employee_one?.employeeid;
            } else {
                if (formData.begindate !== '') {
                    const employeeDateInfo = {variables: {employeeDateInfo: {}}};
                    employeeDateInfo.variables.employeeDateInfo.employee_id = data.employeeid;
                    employeeDateInfo.variables.employeeDateInfo.begin_date = dayjs(formData.begindate).format('YYYY-MM-DD');
                    if (formData.enddate !== '') {
                        employeeDateInfo.variables.employeeDateInfo.end_date = dayjs(formData.enddate).format('YYYY-MM-DD');
                    }
                    if(data.employee_date_histories.length > 0) {
                        employeeDateInfo.variables.employeeDateInfo.employee_date_history_id = data.employee_date_histories[0].employee_date_history_id;
                    }
                    const {data: updateHistoryResult} = await updateDateHistory(employeeDateInfo);
                    success = !!updateHistoryResult?.update_employee_date_history_by_pk?.employee_date_history_id;
                }

                const { data: updateResult } = await updateEmployee({
                    variables: {
                        employeeId: data.employeeid,
                        employeeInfo: {
                            email: formData.email,
                            firstname: formData.firstname,
                            lastname: formData.lastname,
                            location_id: enterprises.find(enterprise => enterprise.id === formData.manager.id).id,
                            manager_id: formData.manager.id,
                            middleinitial: formData.middleinitial,
                            position_id: formData.position.id,
                        },
                        roleId: formData.role.id,
                    },
                });
                success = !!updateResult?.update_employee_by_pk?.employeeid;
            }

            setIsLoading(false);

            if (showDelete) {
                setShowDelete(false);
            }

            if (success) {
                onClose(true);
                setShowError(false);
            } else {
                setShowError(true);
            }
        },
        [
            data,
            enterprises,
            deleteEmployee,
            insertEmployee,
            isNew,
            onClose,
            setIsLoading,
            setShowDelete,
            setShowError,
            showDelete,
            updateEmployee,
            updateDateHistory,
        ]
    );

    const handleDeleteClick = useCallback(() => setShowDelete(true), [
        setShowDelete,
    ]);

    const handleModalCancel = useCallback(() => {
        if (showDelete) {
            setShowDelete(false);
        } else {
            onClose(false);
        }
    }, [onClose, showDelete, setShowDelete]);

    const renderDeleteConfirm = () => (
        <div className={classes.deleteConfirm}>
            <WarningIcon className={classes.warningIcon} />
            <h2>
                Are you sure you want to delete {data?.firstname} {data?.lastname}?
            </h2>
            <p>This will delete all associated resumes and cannot be undone</p>
        </div>
    );

    const renderForm = () => (
        <form>
            {showError && (
                <Alert severity="error">An unexpected error has occurred</Alert>
            )}
            <Grid container>
                {!isNew && (
                    <Grid item xs={12} className={classes.formRow}>
                        <TextField
                            name="employeeid"
                            label="Employee ID"
                            inputRef={register}
                            variant="outlined"
                            fullWidth
                            readOnly={true}
                            disabled={true}
                            error={Boolean(errors?.employeeid)}
                            helperText={errors?.employeeid?.message}
                            className={classes.textField}
                        />
                    </Grid>
                )}
                <Grid item xs={12} className={classes.formRow}>
                    <TextField
                        name="firstname"
                        label="First Name"
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        required
                        disabled={isLoading}
                        error={Boolean(errors?.firstname)}
                        helperText={errors?.firstname?.message}
                        className={classes.textField}
                    />
                </Grid>
                <Grid item xs={12} className={classes.formRow}>
                    <TextField
                        name="middleinitial"
                        label="Middle Initial"
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        disabled={isLoading}
                        error={Boolean(errors?.middleinitial)}
                        helperText={errors?.middleinitial?.message}
                        className={classes.textField}
                    />
                </Grid>
                <Grid item xs={12} className={classes.formRow}>
                    <TextField
                        name="lastname"
                        label="Last Name"
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        required
                        disabled={isLoading}
                        error={Boolean(errors?.lastname)}
                        helperText={errors?.lastname?.message}
                        className={classes.textField}
                    />
                </Grid>
                <Grid item xs={12} className={classes.formRow}>
                    <TextField
                        name="email"
                        label="Email"
                        type="email"
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        required
                        disabled={isLoading}
                        error={Boolean(errors?.email)}
                        helperText={errors?.email?.message}
                        className={classes.textField}
                    />
                </Grid>
                <Grid item xs={12} lg={8} className={classes.formRow}>
                    <TextField
                        name="begindate"
                        label="Begin Date"
                        type="date"
                        inputRef={register}
                        variant="outlined"
                        disabled={isLoading}
                        error={Boolean(errors?.begindate)}
                        helperText={errors?.begindate?.message}
                        defaultValue={defaultValues.begindate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={8} className={classes.formRow}>
                    <TextField
                        name="enddate"
                        label="End Date"
                        type="date"
                        inputRef={register}
                        variant="outlined"
                        disabled={isLoading}
                        error={Boolean(errors?.enddate)}
                        helperText={errors?.enddate?.message}
                        defaultValue={defaultValues.enddate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={8} className={classes.formRow}>
                    <ControlledAutocomplete
                        name="manager"
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={managers}
                        getOptionLabel={option => `${option?.employee.firstname} ${option?.employee.lastname}` || ""}
                        getOptionSelected={(option, value) => value && option.id === value.id}
                        defaultValue={defaultValues.manager}
                        control={control}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Manager"
                                variant="outlined"
                                required
                                error={Boolean(errors?.manager)}
                                helperText={errors?.manager?.message}
                                className={classes.textField}
                            />
                        )}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12} lg={8} className={classes.formRow}>
                    <ControlledAutocomplete
                        name="position"
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={positions}
                        getOptionLabel={(option) => option?.title || ""}
                        getOptionSelected={(option, value) =>
                            value && option.id === value.id
                        }
                        defaultValue={defaultValues.position}
                        control={control}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Position"
                                variant="outlined"
                                required
                                error={Boolean(errors?.position)}
                                helperText={errors?.position?.message}
                                className={classes.textField}
                            />
                        )}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12} lg={8} className={classes.formRow}>
                    <ControlledAutocomplete
                        name="role"
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={roles}
                        getOptionLabel={(option) => option?.authority || ""}
                        getOptionSelected={(option, value) =>
                            value && option.id === value.id
                        }
                        defaultValue={defaultValues.role}
                        control={control}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Role"
                                variant="outlined"
                                required
                                error={Boolean(errors?.role)}
                                helperText={errors?.role?.message}
                                className={classes.textField}
                            />
                        )}
                        disabled={isLoading}
                    />
                </Grid>
            </Grid>
        </form>
    );

    return (
        <ResumeModal
            title={title}
            disabled={isLoading}
            open={open}
            actionButtonName={showDelete ? "Delete" : "Save"}
            handleClose={handleModalCancel}
            handleSubmit={showDelete ? onSubmit : handleSubmit(onSubmit)}
            customActions={
                !isNew &&
                !showDelete &&
                !isSelf && (
                    <Button
                        color="primary"
                        title="Delete"
                        className={classes.deleteButton}
                        startIcon={<DeleteOutlineIcon className={classes.actionIcon} />}
                        disabled={isLoading}
                        onClick={handleDeleteClick}
                    >
                        Delete
                    </Button>
                )
            }
        >
            {showDelete ? renderDeleteConfirm() : renderForm()}
            <LoaderOverlay
                showSpinner={isLoading}
                showOverlay={isLoading}
                color="white"
            />
        </ResumeModal>
    );
}

EditEmployeeInfo.propTypes = {
    data: PropTypes.object,
    managers: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    positions: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
    deleteButton: {
        marginRight: "auto",
    },
    deleteConfirm: {
        textAlign: "center",
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(6),
    },
    formRow: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    warningIcon: {
        fontSize: 60,
        color: theme.palette.warning.main,
    },
}));

export default EditEmployeeInfo;