import React, { useContext, useState } from "react";
import { Box, Breadcrumbs, Button, Link } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import ResumeList from "./resume-list";
import { ResumeContext } from "../../context/resume-context";
import Resume from "./resume";
import Header from "../common/header";
import { useQuery } from "graphql-hooks";
import { EMPLOYEE_BY_ID } from "../../graphql/query";
import EditResumeInfo from "./resume-sections/resume-info/edit-resume-info";

function MyResumes() {
  const classes = useStyles();
  const { resume, updateResume, user, updateResumeList } = useContext(
    ResumeContext
  );
  const [isCreateResume, setIsCreateResume] = useState(false);
  const employeeId = user.signInUserSession.idToken.payload.dbsub;

  const { data } = useQuery(EMPLOYEE_BY_ID, {
    variables: {
      employeeId,
    },
  });

  return (
    <Box className={classes.root}>
      <Header>
        <Breadcrumbs className={classes.breadcrumb} separator=">">
          <Link
            onClick={() => {
              updateResume(null);
            }}
            className={classes.link}
          >
            MY RESUMES
          </Link>
          {resume ? (
            <Link onClick={() => {}} className={classes.link}>
              {resume.resume_name.toUpperCase()}
            </Link>
          ) : null}
        </Breadcrumbs>

        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsCreateResume(true)}
        >
          add resume
        </Button>
        <EditResumeInfo
          open={isCreateResume}
          handleClose={(resume) => {
            setIsCreateResume(false);
            if (resume) {
              updateResume(resume.insert_resume_one);
            }
            updateResumeList(true);
          }}
          title="New Resume"
          data={{
            employee_id: employeeId,
          }}
          isNew
        />
      </Header>

      <Grid container spacing={3}>
        <Grid item sm={3}>
          {data && (
            <Box className={classes.sticky}>
              <div>
                <strong>{data.employee_by_pk.firstname} {data.employee_by_pk.lastname}</strong>
              </div>
              <div>{data.employee_by_pk.position.title}</div>
            </Box>
          )}
        </Grid>
        <Grid item sm={9}>
          {resume ? <Resume /> : <ResumeList />}
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  header: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderColor: theme.palette.grey[400],
  },
  breadcrumb: {
    margin: "6px 8px",
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),

    "&:hover": {
      boxShadow: `0 2px 8px ${alpha(theme.palette.primary.main, 0.4)}`,
      cursor: "pointer",
    },
    "& $header:hover": {
      cursor: "default",
    },
  },
  sticky: {
    position: "sticky",
    top: "50px",
  },
  link: {
    cursor: "pointer",
  },
}));

export default MyResumes;
