import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DayjsUtils from "@material-ui/pickers/adapter/dayjs";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import ResumeModal from "../../../common/modal";
import dayjs from "dayjs";
import { useMutation } from "graphql-hooks";
import {
  DELETE_EXPERIENCE_BULLETS,
  UPSERT_EXPERIENCE,
} from "../../../../graphql/query";
import { ResumeContext } from "../../../../context/resume-context";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";
import uniqueId from "lodash/uniqueId";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 200,
  },
  expBullet: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-around",
    alignItems: "center",
  },
  deleteButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const validationSchema = yup.object().shape({
  employer_type: yup.string(),
  company: yup.string().required("Employer is a required field"),
  role: yup.string().required("Position is a required field"),
  startDate: yup.date().required("Start date is a required field").typeError("Start date is invalid"),
  endDate: yup.date().when('current_position', {
    is: false,
    then: yup.date().required("End date is a required field").typeError("End date is invalid")
  })
});

export function EditExperiences({ experience, open, handleClose }) {
  const classes = useStyles();
  const [expBulletIdsToDelete, setExpBulletIdsToDelete] = useState([]);
  const { resume, updateRefreshNeeded } = useContext(ResumeContext);
  const [deleteExperienceBullets] = useMutation(DELETE_EXPERIENCE_BULLETS);
  const [upsertExperience] = useMutation(UPSERT_EXPERIENCE);

  const defaultValues = experience.id
    ? {
        ...experience,
        startDate: new Date(
          experience.start_year,
          experience.start_month - 1,
          1
        ),
        endDate: experience.current_position
          ? null
          : new Date(experience.end_year, experience.end_month - 1, 1),
      }
    : {
        current_position: false,
        startDate: null,
        endDate: null,
      };

  const { register, control, errors, handleSubmit, reset, watch } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "experience_bullets",
  });

  const hasEndDate = !watch("current_position");

  useEffect(() => {
    reset(experience);
  }, [experience, reset]);

  const removeExperienceBullet = (eb, index) => {
    remove(index);
    if (!eb.id.toString().startsWith("temp_")) {
      setExpBulletIdsToDelete([...expBulletIdsToDelete, eb.id]);
    }
  };

  const onSubmit = async (formData) => {
    const promises = [];
    if (expBulletIdsToDelete.length) {
      promises.push(
        deleteExperienceBullets({
          variables: {
            experience_bullet_ids: expBulletIdsToDelete,
          },
        })
      );
    }
    const { startDate, endDate, ...experienceData } = formData;
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    const experience_bullet_data = formData.experience_bullets
      ? formData.experience_bullets.map((sb) =>
          sb.id.toString().startsWith("temp_") ? { ...sb, id: undefined } : sb
        )
      : [];

    const updatedExperience = {
      ...experienceData,
      id: experience.id,
      resume_id: resume.id,
      experience_type: experience.experience_type,
      employer_id: experience.employer_id,
      start_month: start.month() + 1,
      start_year: start.year(),
      end_month: formData.current_position ? null : end.month() + 1,
      end_year: formData.current_position ? null : end.year(),
      experience_bullets: {
        data: experience_bullet_data,
        on_conflict: {
          constraint: `experience_bullet_pkey`,
          update_columns: [`id`, `bullet_text`],
        },
      },
    };

    promises.push(
      upsertExperience({
        variables: {
          experience: updatedExperience,
        },
      })
    );

    await Promise.all(promises);
    setExpBulletIdsToDelete([]);
    updateRefreshNeeded(true);
    handleClose();
  };

  return (
    <ResumeModal
      title={
        experience.company
          ? `Edit ${experience.company}`
          : `Create new experience`
      }
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <LocalizationProvider dateAdapter={DayjsUtils}>
        <form>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Controller
                    name="current_position"
                    control={control}
                    defaultValue={defaultValues.current_position}
                    render={({ onChange, value, ref, ...props }) => (
                      <Checkbox
                        {...props}
                        color="primary"
                        onChange={e => onChange(e.target.checked)}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                }
                label="Current position?"
              />
            </Grid>
            {experience.experience_type === "E" && (
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  className={classes.formControl}
                >
                  <InputLabel id="employerType">Employer Type</InputLabel>
                  <Controller
                    name="employer_type"
                    control={control}
                    inputRef={register}
                    labelId="employerType"
                    label="Employer Type"
                    autoWidth
                    defaultValue=""
                    as={
                      <Select error={Boolean(errors.employer_type)}>
                        <MenuItem value="N">Non-consulting</MenuItem>
                        <MenuItem value="C">Consulting</MenuItem>
                      </Select>
                    }
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                name="company"
                label="Employer"
                variant="outlined"
                fullWidth
                className={classes.textField}
                inputRef={register}
                error={Boolean(errors.company)}
                helperText={errors?.company?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="role"
                label={
                  experience.experience_type === "P"
                    ? "Role in Project"
                    : "Position"
                }
                variant="outlined"
                fullWidth
                className={classes.textField}
                inputRef={register}
                error={Boolean(errors.role)}
                helperText={errors?.role?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="normal">
                <Controller
                  name="startDate"
                  control={control}
                  inputRef={register}
                  defaultValue={defaultValues.startDate}
                  as={
                    <DatePicker
                      variant="outlined"
                      autoOk
                      openTo="year"
                      views={["year", "month"]}
                      inputFormat="MM/YYYY"
                      mask="__/____"
                      label="Start Date"
                      onChange={() => {}}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          required
                          variant="outlined"
                          helperText={errors.startDate?.message || `Month and year`}
                          error={Boolean(errors.startDate)}
                        />
                      )}
                    />
                  }
                />
              </FormControl>
            </Grid>
            {hasEndDate && (
              <Grid item xs={12}>
                <FormControl variant="outlined" margin="normal">
                  <Controller
                    name="endDate"
                    control={control}
                    inputRef={register}
                    defaultValue={defaultValues.endDate}
                    as={
                      <DatePicker
                        variant="outlined"
                        autoOk
                        openTo="year"
                        views={["year", "month"]}
                        inputFormat="MM/YYYY"
                        mask="__/____"
                        label="End Date"
                        onChange={() => {}}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            required
                            variant="outlined"
                            helperText={errors.endDate?.message || `Month and year`}
                            error={Boolean(errors.endDate)}
                          />
                        )}
                      />
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {experience.experience_type === "P" && (
              <>
                <Grid item xs={12}>
                  <TextField
                    name="industry_description"
                    label="Project Title / Description"
                    inputRef={register}
                    variant="outlined"
                    multiline
                    fullWidth
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="tech_tools_bullet"
                    label="Tech Tools Used"
                    inputRef={register}
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                  />
                </Grid>
              </>
            )}
            {fields.map((eb, index) => (
              <Grid item xs={12} key={eb.id} className={classes.expBullet}>
                <Grid item xs={10}>
                  <input
                    type="hidden"
                    name={`experience_bullets[${index}].id`}
                    defaultValue={fields[index].id}
                    ref={register()}
                  />
                  <TextField
                    name={`experience_bullets[${index}].bullet_text`}
                    defaultValue={fields[index].bullet_text}
                    inputRef={register()}
                    variant="outlined"
                    fullWidth
                    required
                    label="Experience Bullet"
                    className={classes.textField}
                    error={Boolean(
                      errors.summary_bullets &&
                        errors.summary_bullets[index]?.bullet_text
                    )}
                    helperText={
                      errors.summary_bullets &&
                      errors.summary_bullets[index]?.bullet_text?.message
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={2}
                  className={classes.deleteButtonContainer}
                >
                  <IconButton
                    color="default"
                    onClick={() => removeExperienceBullet(fields[index], index)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            {experience.experience_type === "P" && (
              <Button
                color="primary"
                startIcon={<AddIcon />}
                onClick={() =>
                  append({
                    id: uniqueId("temp_"),
                    bullet_text: "",
                  })
                }
              >
                add experience bullet
              </Button>
            )}
          </Grid>
        </form>
      </LocalizationProvider>
    </ResumeModal>
  );
}
