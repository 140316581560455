import React, { useState } from "react";

export const ResumeContext = React.createContext();

export const ResumeProvider = (props) => {
  const [resume, setResume] = useState(null);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const [user, setUser] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [listRefreshNeeded, setListRefreshNeeded] = useState(false);

  const userRole = user?.signInUserSession?.idToken?.payload?.dbrole || null;

  const updateResume = (resume) => {
    setResume(resume);
  };

  const updateRefreshNeeded = (refreshNeeded) => {
    setRefreshNeeded(refreshNeeded);
  };

  const updateUser = (user) => {
    setUser(user);
  };

  const updateIsLoggedIn = (isLoggedIn) => {
    setIsLoggedIn(isLoggedIn);
  };

  const updateResumeList = (listRefreshNeeded) => {
    setListRefreshNeeded(listRefreshNeeded);
  };

  return (
    <ResumeContext.Provider
      value={{
        resume,
        updateResume,
        refreshNeeded,
        updateRefreshNeeded,
        listRefreshNeeded,
        updateResumeList,
        user,
        userRole,
        updateUser,
        isLoggedIn,
        updateIsLoggedIn,
      }}
    >
      {props.children}
    </ResumeContext.Provider>
  );
};
