import React from "react";
import { AppBar, Box } from "@material-ui/core";
import logo from "../images/improving_logo_complete_flat.png";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

function Navbar() {
  return (
    <Box flexGrow={1}>
      <AppBar position="static" color="default">
        <Box
          m={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <img src={logo} height="60" alt="Improving logo" />
          <AmplifySignOut onClick={() => Auth.signOut()}/>
        </Box>
      </AppBar>
    </Box>
  );
}

export default Navbar;
