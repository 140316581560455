import React, { useEffect, useState } from 'react';
import { useManualQuery } from 'graphql-hooks';
import { RESUME_BY_ID } from '../../graphql/query';
import { Box, Grid, List, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { certTypes } from '../my-resumes/resume-sections/certification-training/cert-types'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    textTransform: 'uppercase'
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  paper: {
    background: "#ddd",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
  alignRight: {
    textAlign: 'right'
  }
}));

const getFullName = (employee) => {
  return ['firstname', 'middleinitial', 'lastname'].map(segment => {
    if (employee.hasOwnProperty(segment)) {
      return employee[segment];
    }
    return '';
  }).join(' ');
}

export const ResumeNoEdit = ({match}) => {
  const [getResumeById] = useManualQuery(RESUME_BY_ID);
  const [resumeData, setResumeData] = useState();
  const classes = useStyles();

  useEffect(() => {
    getResumeById({
      variables: {
        resumeId: `${match.params.id}`
      },
    }).then(({data}) => {
      if (data.resume_by_pk) {
        setResumeData(data.resume_by_pk);
      }
    });
    // eslint-disable-next-line
  }, []);

  const renderResume = (resume) => (
    <>
      <Box mb={3}>
        <h3 style={{textTransform: 'uppercase'}}>Summary</h3>
        <Box>{resume.summary}</Box>
        <h3 className={classes.header}>Technical Skills</h3>
        <Box mt={1}>
          {resume.resume_categories.map((skill_category) => (
            <Box key={skill_category.id} className={classes.container}>
              <Box fontWeight="fontWeightMedium">{skill_category.label}</Box>
              <Box>
                {skill_category.resume_category_skills
                  .map((skill) => skill.label)
                  .join(", ")}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box mb={3}>
        <h3 className={classes.header}>Experiences</h3>
        <Box mt={1} mb={2}>
          {resume.experiences.map((experience) => (
            <React.Fragment key={experience.id}>
              <Box className={classes.container}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} md={8}>
                    <Box fontWeight="fontWeightMedium">{experience.company}
                      {experience.role &&
                        <>{' - '}<span style={{fontWeight: "initial"}}>({experience.role})</span></>
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className={classes.alignRight}>
                      {experience.start_month}/{experience.start_year} -{" "}
                      {experience.current_position
                        ? "current"
                        : `${experience.end_month}/${experience.end_year}`}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {experience.experiences.map((projectExp) => (
                <Box key={`${experience.id}-${projectExp.id}`} className={classes.container}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={8}>
                      <Box>
                        {experience.employer_type === "N" ? projectExp.role : projectExp.company}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box className={classes.alignRight}>
                        {projectExp.start_month}/{projectExp.start_year} -{" "}
                        {projectExp.current_position
                          ? "current"
                          : `${projectExp.end_month}/${projectExp.end_year}`}
                      </Box>
                    </Grid>
                  </Grid>
                  {projectExp.industry_description && (
                    <Box className={classes.container}>
                      {projectExp.industry_description}
                    </Box>
                  )}
                  {Boolean(projectExp.experience_bullets.length) && (
                    <Box className={classes.container} ml={1}>
                      <List style={{listStyle: 'initial', paddingTop: 0}}>
                        {projectExp.experience_bullets.map(expBullet => (
                          <li key={`${experience.id}-${projectExp.id}-${expBullet.id}`}>{expBullet.bullet_text}</li>
                        ))}
                      </List>
                    </Box>
                  )}
                </Box>
              ))}
            </React.Fragment>
          ))}
        </Box>
      </Box>

      <Box mb={3}>
        <h3 className={classes.header}>Education</h3>
        <Box mt={1}>
          {resume.education.map((ed) => (
            <Box key={ed.id} className={classes.container}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Box fontWeight="fontWeightMedium">{ed.school}</Box>
                  <Box>{ed.degree}</Box>
                </Grid>
                <Grid item xs={12} md={4} className={classes.alignRight}>
                  <Box>
                    {ed.graduated
                      ? `${ed.graduation_month}/${ed.graduation_year}`
                      : "current"}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>

      <Box mb={3}>
        <h3 className={classes.header}>Certification &amp; Training</h3>
        <Box mt={1}>
          {resume.certification_trainings.map((ct) => (
            <Box className={classes.container} key={ct.id}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Box fontWeight="fontWeightMedium">
                    {ct.certification_training_name}
                  </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Box>{certTypes.get(ct.type)}</Box>
                </Grid>
                <Grid item xs={12} md={2} className={classes.alignRight}>
                  <Box>
                    {ct.certification_training_month}/
                    {ct.certification_training_year}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );

  ResumeNoEdit.propTypes = {
    match: PropTypes.object.isRequired
  };

  return (
    <>
      {resumeData &&
        <Box>
          <Paper className={classes.paper}>
            <Box style={{
              maxWidth: 820,
              margin: 'auto',
              padding: '40px 60px',
              background: '#fafafa'
            }}>
              <h3 className={classes.header} style={{textTransform: 'none'}}>
                {getFullName(resumeData.employee)}
              </h3>
              {renderResume(resumeData)}
            </Box>
          </Paper>
        </Box>
      }
    </>
  );
}
