import React, { useMemo } from "react";
import PropTypes from 'prop-types';

import { ClientContext, GraphQLClient } from "graphql-hooks";
import memCache from "graphql-hooks-memcache";

import { useNotifications } from "../components/common/notifications";

export function GraphQLProvider({ url, ...props }) {
  const notifications = useNotifications();

  const graphQLClient = useMemo(() => (
    new GraphQLClient({
      url,
      cache: memCache(),
      onError: ({ operation, result }) => {
        notifications.error('The operation could not be completed', (
          <>
            <h4>Attempted the following data operation:</h4>
            <p>{JSON.stringify(operation, null, 2)}</p>
            <h4>Received an error result:</h4>
            <p>{JSON.stringify(result, null, 2)}</p>
          </>
        ));
      }
    })
  ), [notifications, url]);

  return <ClientContext.Provider {...props} value={graphQLClient} />;
}

GraphQLProvider.propTypes = {
  url: PropTypes.string.isRequired
};
