import React, { useContext, useState } from "react";
import {
  Box,
  Card,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ClearIcon from "@material-ui/icons/Clear";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import { useMutation, useQuery } from "graphql-hooks";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ResumeModal from "../../../common/modal";
import {
  CREATE_RESUME_SKILLS,
  CREATE_SKILLS,
  DELETE_RESUME_SKILLS_BY_CATEGORY,
  SKILLS_BY_CATEGORY,
} from "../../../../graphql/query";
import { ResumeContext } from "../../../../context/resume-context";

const filter = createFilterOptions();

export const EditSkills = ({ open, skillCategory, handleClose, skills }) => {
  const { resume, updateRefreshNeeded } = useContext(ResumeContext);
  const [selectedSkills, setSelectedSkills] = useState(skills);

  const { data, loading, refetch } = useQuery(SKILLS_BY_CATEGORY, {
    variables: {
      skillCategoryId: skillCategory.id,
    },
  });
  const [createSkills] = useMutation(CREATE_SKILLS);
  const [deleteResumeSkillsByCategory] = useMutation(
    DELETE_RESUME_SKILLS_BY_CATEGORY
  );
  const [createResumeSkills] = useMutation(CREATE_RESUME_SKILLS);

  const onSelectedSkillRemove = (id) => {
    setSelectedSkills(selectedSkills.filter((skill) => skill.id !== id));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    setSelectedSkills(
      arrayMove(selectedSkills, result.source.index, result.destination.index)
    );
  };

  const SortableList = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="why">
        {(droppableProvided) => (
          <List
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {selectedSkills.map((skill, index) => (
              <Draggable
                key={skill.id}
                draggableId={`${skill.id}`}
                index={index}
              >
                {(draggableProvided) => (
                  <ListItem
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={{
                      ...draggableProvided.draggableProps.style,
                    }}
                  >
                    <ListItemIcon>
                      <DragHandleIcon />
                    </ListItemIcon>
                    <ListItemText primary={skill.label} />
                    <ListItemIcon>
                      <IconButton
                        onClick={() => onSelectedSkillRemove(skill.id)}
                      >
                        <ClearIcon fontSize="small" color="primary" />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );

  const onSubmit = async () => {
    await deleteResumeSkillsByCategory({
      variables: {
        resumeId: resume.id,
        skillCategoryId: skillCategory.id,
      },
    });
    await createResumeSkills({
      variables: {
        resumeSkills: selectedSkills.map((skill, index) => ({
          skill_id: skill.id,
          resume_id: resume.id,
          sort_order: index,
        })),
      },
    });
    updateRefreshNeeded(true);
    handleClose();
  };

  return (
    <ResumeModal
      title={`Edit ${skillCategory.label}`}
      open={open}
      handleClose={handleClose}
      handleSubmit={onSubmit}
    >
      {loading && <div>Loading...</div>}
      {data && (
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Card>
              <Box m={2}>
                <Autocomplete
                  multiple
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  defaultValue={skills}
                  value={selectedSkills}
                  options={loading ? [] : data.skill}
                  getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.label;
                  }}
                  getOptionSelected={(option, value) => isEqual(option, value)}
                  renderTags={() => null}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (filtered.length === 0 && params.inputValue !== "") {
                      filtered.push({
                        id: "temp",
                        inputValue: `Add skill "${params.inputValue}"`,
                        label: params.inputValue,
                      });
                    }
                    return filtered;
                  }}
                  onChange={async (event, value, reason) => {
                    const difference =
                      reason !== "remove-option"
                        ? differenceWith(value, selectedSkills, isEqual)
                        : differenceWith(selectedSkills, value, isEqual);

                    const diffObj = difference[0];
                    // this is a new item created from the "Add skill" option
                    if (diffObj.id === "temp") {
                      const { data: newSkillData } = await createSkills({
                        variables: {
                          skills: [
                            {
                              ...diffObj,
                              id: undefined,
                              inputValue: undefined,
                              skill_category_id: skillCategory.id,
                            },
                          ],
                        },
                      });
                      setSelectedSkills([
                        ...selectedSkills,
                        newSkillData.insert_skill.returning[0],
                      ]);
                      await refetch();
                    } else {
                      setSelectedSkills(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={skillCategory.label}
                      variant="outlined"
                    />
                  )}
                />
                <SortableList />
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
    </ResumeModal>
  );
};
