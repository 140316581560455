import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ResumeSectionHeader from "../section-header";
import { EditSkills } from "./edit-skills";

function TechnicalSkills({ skillCategories }) {
  const classes = useStyles();
  const [selectedSkillCategory, setSelectedSkillCategory] = useState(null);

  return (
    <>
      <Box mb={3}>
        <ResumeSectionHeader title="Technical Skills" height={45} />
        {skillCategories.map((skill_category) => (
          <Box mt={1} key={skill_category.id}>
            <Box className={classes.skillContainer}>
              <Box className={classes.skillHeader}>
                <Box fontWeight="fontWeightMedium">{skill_category.label}</Box>
                <IconButton
                  color="primary"
                  title={`edit ${skill_category.label}`}
                  onClick={() => {
                    setSelectedSkillCategory(skill_category);
                  }}
                >
                  <EditIcon className={classes.actionIcon} />
                </IconButton>
              </Box>
              <Box>
                {skill_category.resume_category_skills
                  .map((skill) => skill.label)
                  .join(", ")}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {selectedSkillCategory && (
        <EditSkills
          open={Boolean(selectedSkillCategory)}
          skillCategory={selectedSkillCategory}
          handleClose={() => {
            setSelectedSkillCategory(null);
          }}
          skills={selectedSkillCategory.resume_category_skills.map(
            ({ skill_id, label }) => ({
              id: skill_id,
              label,
            })
          )}
        />
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  skillContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    "&:hover": {
      background: theme.palette.grey[100],
    },
  },
  skillHeader: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionIcon: {
    fontSize: "1.2rem",
  },
}));

export default TechnicalSkills;
