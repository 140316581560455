import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

const ControlledAutocomplete = ({
  control,
  defaultValue,
  disabled = false,
  getOptionLabel,
  name,
  onChange: ignored,
  options = [],
  renderInput,
  renderOption,
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          onChange={(e, data) => onChange(data)}
          disabled={disabled}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};

ControlledAutocomplete.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  renderInput: PropTypes.func,
  renderOption: PropTypes.func,
};

export default ControlledAutocomplete;
