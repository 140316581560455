
export const withoutEmptyProps = (obj) => {
    const copy = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key] !== null && typeof obj[key] !== "undefined") {
            copy[key] = obj[key];
        }
    });
    return copy;
};

export const trimStrings = (obj) => {
    const copy = {};
    Object.keys(obj).forEach((key) => {
        copy[key] = typeof obj[key] === "string" ? obj[key].trim() : obj[key];
    });
    return copy;
};

export const sortSkills = (data) => ({
    ...data,
    employee: data.employee.sort((a, b) =>  b.resumes[0].resume_skills.length - a.resumes[0].resume_skills.length)
});

export const employeesNoEndDate = (data) => ({
    ...data,
    employee: data.employee.filter(f => f.employee_date_histories.length === 0
        || (f.employee_date_histories.length > 0 &&
              (f.employee_date_histories[0].end_date === null
                  || new Date(f.employee_date_histories[0].end_date).getTime() >= new Date().getTime())))
});