import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import TablePaginationActions from "../common/table-pagination-actions";

const headCells = [
  { id: "employeeid", align: "left", label: "ID", allowSort: true },
  { id: "firstname", align: "left", label: "First Name", allowSort: true },
  { id: "lastname", align: "left", label: "Last Name", allowSort: true },
  { id: "manager_id", align: "left", label: "Manager", allowSort: true },
  { id: "enterprise", align: "left", label: "Enterprise", allowSort: true},
  { id: "state", align: "left", label: "Office", allowSort: true},
  { id: "position_id", align: "left", label: "Position", allowSort: true },
  { id: "employee_roles", align: "left", label: "Role", allowSort: false },
];

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableRow: {
    cursor: "pointer",
  },
}));

function EmployeeResultList(props) {
  const {
    count,
    employees,
    enterprises,
    managers,
    onPageChange,
    onChangeSort,
    onRowClick,
    page,
    positions,
    roles,
    rowsPerPage,
    sortDirection,
    sortOrder,
  } = props;
  const classes = useStyles();

  const getManagerName = (id) => {
    const manager = managers && managers.find(manager => manager.id === id);
    return manager ? `${manager.employee.firstname} ${manager.employee.lastname}` : "N/A";
  };

  const getEnterprise = id => {
    const manager = managers && managers.find(manager => manager.id === id);
    const enterprise = enterprises && enterprises.find(enterprise => enterprise.id === manager.id);
    return enterprise.enterprise_name;
  }

  const getManagerLocation = (id) => {
    const manager = managers && managers.find(manager => manager.id === id);
    return manager ? `${manager.city}, ${manager.state}` : "N/A";
  }

  const getPositionName = (id) => {
    const position = positions && positions.find((p) => p.id === id);
    return position?.title || "N/A";
  };

  const getRoleName = (employeeRoles) => {
    if (Array.isArray(employeeRoles) && employeeRoles.length) {
      const role =
        roles && roles.find((r) => r.id === employeeRoles[0].role_id);
      if (role) {
        return role.authority;
      }
    }
    return "N/A";
  };

  const createSortHandler = (property) => (event) => {
    onChangeSort(event, property);
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                sortDirection={
                  sortOrder === headCell.id ? sortDirection : false
                }
              >
                {headCell.allowSort ? (
                  <TableSortLabel
                    active={sortOrder === headCell.id}
                    direction={
                      sortOrder === headCell.id ? sortDirection : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {sortOrder === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {sortDirection === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {employees?.map((employee) => (
            <TableRow
              key={employee.employeeid}
              hover
              className={classes.tableRow}
              onClick={() => onRowClick(employee)}
            >
              <TableCell>{employee.employeeid}</TableCell>
              <TableCell>{employee.firstname}</TableCell>
              <TableCell>{employee.lastname}</TableCell>
              <TableCell>{getManagerName(employee.manager_id)}</TableCell>
              <TableCell>{getEnterprise(employee.manager_id)}</TableCell>
              <TableCell>{getManagerLocation(employee.manager_id)}</TableCell>
              <TableCell>{getPositionName(employee.position_id)}</TableCell>
              <TableCell>{getRoleName(employee.employee_roles)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={count || 0}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              page={page || 0}
              onPageChange={onPageChange}
              ActionsComponent={TablePaginationActions} />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

EmployeeResultList.propTypes = {
  count: PropTypes.number.isRequired,
  employees: PropTypes.array.isRequired,
  enterprises: PropTypes.array.isRequired,
  managers: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  sortOrder: PropTypes.string,
  sortDirection: PropTypes.oneOf(["asc", "desc"]),
  page: PropTypes.number.isRequired,
  positions: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default EmployeeResultList;
