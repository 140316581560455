import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DayjsUtils from "@material-ui/pickers/adapter/dayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useMutation } from "graphql-hooks";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ResumeContext } from "../../../../context/resume-context";
import {
  CREATE_CERTIFICATION_TRAINING,
  UPDATE_CERTIFICATION_TRAINING,
} from "../../../../graphql/query";
import ResumeModal from "../../../common/modal";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 400,
    width: "100%",
  },
}));

const validationSchema = yup.object().shape({
  type: yup.string().required().oneOf(["C", "T"], "type is a required field"),
  certification_training_name: yup.string().required(),
  training_provider: yup.string().required(),
  certTrainingDate: yup
    .date()
    .required("date is required")
    .typeError("date is invalid"),
});

dayjs.extend(utc);

export function EditCertificationAndTraining({
  certTraining,
  open,
  handleClose,
}) {
  const classes = useStyles();
  const { resume, updateRefreshNeeded } = useContext(ResumeContext);

  const [createCertTraining] = useMutation(CREATE_CERTIFICATION_TRAINING);
  const [updateCertTraining] = useMutation(UPDATE_CERTIFICATION_TRAINING);

  const defaultValues = certTraining.id
    ? {
        ...certTraining,
        certTrainingDate: dayjs()
          .utc()
          .month(certTraining.certification_training_month - 1)
          .year(certTraining.certification_training_year)
          .startOf("month"),
      }
    : {
        certTrainingDate: null,
      };

  const { control, errors, handleSubmit, register, reset } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(certTraining);
  }, [certTraining, reset]);

  const onSubmit = async (formData) => {
    const { certTrainingDate, ...certTrainingData } = formData;
    const certTrainingDayJs = dayjs(certTrainingDate);
    const updatedCertTraining = {
      ...certTrainingData,
      resume_id: resume.id,
      certification_training_month: certTrainingDayJs.month() + 1,
      certification_training_year: certTrainingDayJs.year(),
    };

    if (certTraining.id) {
      await updateCertTraining({
        variables: {
          id: certTraining.id,
          cert_training: updatedCertTraining,
        },
      });
    } else {
      await createCertTraining({
        variables: {
          cert_training: updatedCertTraining,
        },
      });
    }
    updateRefreshNeeded(true);
    handleClose();
  };

  return (
    <ResumeModal
      title={
        certTraining.id
          ? `Edit ${certTraining.certification_training_name}`
          : `Create new certificate/training`
      }
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <LocalizationProvider dateAdapter={DayjsUtils}>
        <form>
          <Grid container>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                margin="normal"
                required
                error={Boolean(errors.type)}
                className={classes.formControl}
              >
                <InputLabel id="typeLabel">Type</InputLabel>
                <Controller
                  name="type"
                  control={control}
                  inputRef={register}
                  labelId="typeLabel"
                  label="Type"
                  autoWidth
                  defaultValue=""
                  as={
                    <Select autoWidth>
                      <MenuItem value="C">Certification</MenuItem>
                      <MenuItem value="T">Training</MenuItem>
                    </Select>
                  }
                />
                <FormHelperText>{errors?.type?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="certification_training_name"
                label="Name"
                inputRef={register}
                variant="outlined"
                fullWidth
                required
                className={classes.textField}
                error={Boolean(errors.certification_training_name)}
                helperText={errors?.certification_training_name?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="training_provider"
                label="Provider"
                inputRef={register}
                variant="outlined"
                fullWidth
                required
                className={classes.textField}
                error={Boolean(errors.training_provider)}
                helperText={errors?.training_provider?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="normal" required>
                <Controller
                  name="certTrainingDate"
                  control={control}
                  inputRef={register}
                  defaultValue={defaultValues.certTrainingDate}
                  as={
                    <DatePicker
                      variant="outlined"
                      autoOk
                      openTo="year"
                      views={["year", "month"]}
                      inputFormat="MM/YYYY"
                      mask="__/____"
                      label="Date"
                      onChange={() => {}}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          error={Boolean(errors.certTrainingDate)}
                          variant="outlined"
                          helperText="Month and year"
                        />
                      )}
                    />
                  }
                />
                <FormHelperText>
                  {errors?.certTrainingDate?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </LocalizationProvider>
    </ResumeModal>
  );
}
