import { Box, Breadcrumbs, Button, Link, Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { useManualQuery, useQuery } from "graphql-hooks";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ALL_ENTERPRISES,
  ALL_LOCATIONS,
  ALL_MANAGERS,
  ALL_POSITIONS,
  ALL_ROLES,
  EMPLOYEE_SEARCH,
  getEmployeeSearchVariables,
} from "../../graphql/query";
import Header from "../common/header";
import LoaderOverlay from "../common/loader-overlay";
import SearchFilterPanel from "../common/search-filter-panel";
import EditEmployeeInfo from "./edit-employee-info";
import EmployeeResultList from "./employee-result-list";
import { withoutEmptyProps, trimStrings } from "../../utils/object-utils";

const PAGE_SIZE = 20;

function Employees() {
  const classes = useStyles();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editEmployee, setEditEmployee] = useState(null);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortOrder, setSortOrder] = useState("employeeid");
  const [searchParams, setSearchParams] = useState({
    search: "",
    experience: "",
    enterprises: [],
    locations: [],
    positions: [],
    roles: [],
    limit: PAGE_SIZE,
    offset: 0,
    order_by: { [sortOrder]: sortDirection },
  });
  const [fetchEmployees] = useManualQuery(EMPLOYEE_SEARCH, { skipCache: true });
  const [searchResults, setSearchResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data: locationsData, loading: locationsLoading } = useQuery(
    ALL_LOCATIONS
  );
  const { data: enterpriseData, loading: enterprisesLoading } = useQuery(
      ALL_ENTERPRISES
  );
  const { data: managersData, loading: managersLoading } = useQuery(ALL_MANAGERS)

  const { data: positionsData, loading: positionsLoading } = useQuery(
    ALL_POSITIONS
  );
  const { data: rolesData, loading: rolesLoading } = useQuery(ALL_ROLES);

  const hasResults = !!searchResults?.employee?.length;
  const isInitialLoadComplete =
    (!loading || hasResults) &&
      !enterprisesLoading &&
      !locationsLoading &&
      !managersLoading &&
      !positionsLoading &&
      !rolesLoading;

  const setSearchParamsDebounced = useMemo(
    () =>
      debounce((value) => {
        setSearchParams(withoutEmptyProps(value));
      }, 500),
    []
  );

  const handleAddEmployeeClick = useCallback(() => {
    setEditEmployee(null);
    setShowEditModal(true);
  }, [setEditEmployee, setShowEditModal]);

  const handleEditEmployeeClose = useCallback(
    (updated) => {
      setEditEmployee(null);
      setShowEditModal(false);

      if (updated) {
        setSearchParamsDebounced({
          ...searchParams,
          offset: 0,
        });
      }
    },
    [setShowEditModal, searchParams, setSearchParamsDebounced]
  );

  const handleFilterChange = useCallback(
    (params) => {
      setSearchParamsDebounced({
        ...searchParams,
        ...params,
        offset: 0,
      });
    },
    [searchParams, setSearchParamsDebounced]
  );

  const handlePageChange = useCallback(
    (event, newValue) => {
      setSearchParamsDebounced({
        ...searchParams,
        offset: newValue * PAGE_SIZE,
      });
    },
    [searchParams, setSearchParamsDebounced]
  );

  const handleSortChange = useCallback(
    (event, newSortOrder) => {
      const isAsc = sortOrder === newSortOrder && sortDirection === "asc";
      const newDirection = isAsc ? "desc" : "asc";
      setSortDirection(newDirection);
      setSortOrder(newSortOrder);

      setSearchParamsDebounced({
        ...searchParams,
        offset: 0,
        order_by: {
          [newSortOrder]: newDirection,
        },
      });
    },
    [
      searchParams,
      setSearchParamsDebounced,
      sortOrder,
      sortDirection,
      setSortDirection,
      setSortOrder,
    ]
  );

  const handleEmployeeEdit = useCallback(
    (employee) => {
      setEditEmployee(employee);
      setShowEditModal(true);
    },
    [setEditEmployee, setShowEditModal]
  );

  useEffect(() => {
    async function fetchSearchData() {
      const variables = getEmployeeSearchVariables(
        trimStrings({
          ...searchParams,
        })
      );
      setError(false);
      setLoading(true);

      let result = null;
      try {
        result = await fetchEmployees({ variables });
      } catch (error) {
        setError(error);
      }
      if (result) {
        setSearchResults(result?.data || []);
      }
      setLoading(false);
    }
    fetchSearchData();
  }, [fetchEmployees, searchParams, setError, setLoading, setSearchResults]);

  return (
    <Box className={classes.root}>
      <EditEmployeeInfo
        open={showEditModal}
        onClose={handleEditEmployeeClose}
        data={editEmployee}
        managers={managersData?.enterprise_manager || []}
        positions={positionsData?.position || []}
        roles={rolesData?.role || []}
        enterprises={enterpriseData?.enterprise || []}
      />

      <Header>
        <Breadcrumbs className={classes.breadcrumb} separator=">">
          <Link onClick={() => {}} className={classes.link}>
            EMPLOYEES
          </Link>
        </Breadcrumbs>

        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddEmployeeClick}
        >
          Add Employee
        </Button>
      </Header>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <SearchFilterPanel
            searchLabel="Name"
            experienceSearchLabel="Keyword"
            enterprises={enterpriseData?.enterprise || []}
            locations={locationsData?.enterprise_manager || []}
            positions={positionsData?.position || []}
            roles={rolesData?.role || []}
            params={searchParams}
            showEnterprises
            showLocations
            showPositions
            showRoles
            onParamsChange={handleFilterChange}
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <Paper className={classes.tableWrap}>
            {isInitialLoadComplete && !!error && (
              <div className={classes.info}>
                <h2>Error</h2>
                {JSON.stringify(error)}
              </div>
            )}
            {isInitialLoadComplete && !error && hasResults && (
              <EmployeeResultList
                count={searchResults.employee_aggregate.aggregate.count}
                employees={searchResults.employee}
                enterprises={enterpriseData.enterprise}
                managers={managersData?.enterprise_manager}
                onPageChange={handlePageChange}
                onChangeSort={handleSortChange}
                onRowClick={handleEmployeeEdit}
                page={searchParams.offset / PAGE_SIZE}
                positions={positionsData?.position}
                roles={rolesData?.role}
                rowsPerPage={PAGE_SIZE}
                sortDirection={sortDirection}
                sortOrder={sortOrder}
              />
            )}
            {isInitialLoadComplete && !error && !hasResults && (
              <div className={classes.info}>
                <h3>No Results Found</h3>
              </div>
            )}
            <LoaderOverlay
              showSpinner={!isInitialLoadComplete || loading}
              showOverlay={isInitialLoadComplete && loading}
              color="gray" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  breadcrumb: {
    margin: "6px 8px",
  },
  link: {
    cursor: "pointer",
  },
  tableWrap: {
    alignSelf: "flex-start",
    overflow: "hidden",
    position: "relative",
  },
  info: {
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    textAlign: "center",
  },
}));

export default Employees;
