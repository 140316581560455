import { Button } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Link } from 'react-router-dom';
import React from 'react';

export const WebViewButton = ({ resumeId }) => (
    <Button color="primary" startIcon={<FileCopyOutlinedIcon />}>
        <Link
            to={`resume/${resumeId}`}
            style={{
                color: 'inherit',
                textDecoration: 'none'
            }}
            target="_blank">
            webview
        </Link>
    </Button>
);
