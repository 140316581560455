import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import notificationConsts from '../../constants/notification-consts';

function typeToLabel(type) {
  switch (type) {
    case notificationConsts.INFO:
      return 'Information';
    case notificationConsts.SUCCESS:
      return 'Success';
    case notificationConsts.WARNING:
      return 'Warning';
    case notificationConsts.ERROR:
      return 'Error';
    default:
      return null;
  }
}

export function NotificationDetails({ content, notificationControls, type }) {
  const [open, setOpen] = useState(false);
  const typeLabel = typeToLabel(type)

  function handleOpen() {
    setOpen(true);
    notificationControls.pause();
  }

  function handleClose() {
    setOpen(false);
    notificationControls.resume();
  }

  return (
    <>
      <Button color="inherit" onClick={handleOpen}>{typeLabel} Details</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{typeLabel} Details</DialogTitle>
        <DialogContent>
          {typeof content === 'string' ? (
            <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
          ) : content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

NotificationDetails.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  notificationControls: PropTypes.shape({
    close: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    resume: PropTypes.func.isRequired
  }).isRequired,
  type: PropTypes.oneOf([
    notificationConsts.INFO,
    notificationConsts.SUCCESS,
    notificationConsts.WARNING,
    notificationConsts.ERROR
  ]).isRequired
};
