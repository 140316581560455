import React, { useContext, useEffect } from "react";
import { Box, CircularProgress, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ResumeInfo from "./resume-sections/resume-info/resume-info";
import TechnicalSkills from "./resume-sections/skills/skills";
import Experiences from "./resume-sections/experiences/experiences";
import Education from "./resume-sections/education/education";
import CertificationAndTraining from "./resume-sections/certification-training/cert-training";
import { useQuery } from "graphql-hooks";
import { RESUME_BY_ID } from "../../graphql/query";
import { ResumeContext } from "../../context/resume-context";

function Resume() {
  const classes = useStyles();
  const {
    resume,
    updateResume,
    refreshNeeded,
    updateRefreshNeeded,
  } = useContext(ResumeContext);
  const { data, loading, error, refetch } = useQuery(RESUME_BY_ID, {
    variables: {
      resumeId: `${resume.id}`,
    },
  });
  useEffect(() => {
    async function refetchResumeData() {
      await refetch();
    }
    if (refreshNeeded) {
      refetchResumeData();
      updateRefreshNeeded(false);
    }
  }, [refreshNeeded, updateRefreshNeeded, refetch]);

  useEffect(() => {
    if (data && data.resume_by_pk) {
      updateResume(data.resume_by_pk);
    }
  }, [data, updateResume]);

  return (
    <>
      {loading && (
        <Box
          width="95%"
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {error && <div>{JSON.stringify(error)}</div>}
      {data && (
        <Box width="95%">
          <Paper className={classes.paper}>
            <ResumeInfo
              id={data.resume_by_pk.id}
              resume_name={data.resume_by_pk.resume_name}
              summary={data.resume_by_pk.summary}
              employee_id={data.resume_by_pk.employee_id}
              summary_bullets={data.resume_by_pk.summary_bullets}
            />
            <TechnicalSkills
              skillCategories={data.resume_by_pk.resume_categories}
            />
            <Experiences experiences={data.resume_by_pk.experiences} />
            <Education education={data.resume_by_pk.education} />
            <CertificationAndTraining
              certificationTrainings={data.resume_by_pk.certification_trainings}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default Resume;
