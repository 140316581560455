import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

function ResumeSectionHeader({ title, children, ...rest }) {
  const classes = useStyles();

  return (
    <Box className={classes.header} {...rest}>
      <Box>{title}</Box>
      <Box>{children}</Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",
  },
}));

export default ResumeSectionHeader;
