/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
    "aws_user_pools_id": process.env.REACT_APP_COGNITO_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {
        "domain": process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": process.env.REACT_APP_OAUTH_REDIRECT_URL,
        "redirectSignOut": process.env.REACT_APP_OAUTH_REDIRECT_URL,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "generatePdfApi",
            "endpoint": process.env.REACT_APP_GENERATE_PDF_API_ENDPOINT,
            "region": process.env.REACT_APP_AWS_REGION
        }
    ]
};


export default awsmobile;
