import React, { useState } from 'react';

export const NotificationContext = React.createContext({
  notificationQueue: [],
  enqueue: notificationProps => null,
  dequeue: id => null,
  open: id => null,
  close: id => null,
  pauseAutoHide: id => null,
  resumeAutoHide: id => null
});

let nextNotificationId = 1;
export const NotificationProvider = (props) => {
  const [notificationQueue, setNotificationQueue] = useState([]);

  function enqueue(notificationProps) {
    const notificationId = nextNotificationId++;
    setNotificationQueue(prevNotificationQueue => [...prevNotificationQueue, { ...notificationProps, notificationId }]);
    return notificationId;
  }

  function dequeue(notificationId) {
    setNotificationQueue(prevNotificationQueue => prevNotificationQueue.filter(notificationProps => notificationProps.notificationId !== notificationId));
  }

  function open(notificationId) {
    setNotificationQueue(prevNotificationQueue =>
      prevNotificationQueue.map(notificationProps =>
        notificationProps.notificationId === notificationId ?
          { ...notificationProps, open: true } : notificationProps
      )
    )
  }

  function close(notificationId) {
    setNotificationQueue(prevNotificationQueue =>
      prevNotificationQueue.map(notificationProps =>
        notificationProps.notificationId === notificationId ?
          { ...notificationProps, open: false } : notificationProps
      )
    )
  }

  function pauseAutoHide(notificationId) {
    setNotificationQueue(prevNotificationQueue =>
      prevNotificationQueue.map(notificationProps =>
        notificationProps.notificationId === notificationId ?
          { ...notificationProps, pauseAutoHide: true } : notificationProps
      )
    )
  }

  function resumeAutoHide(notificationId) {
    setNotificationQueue(prevNotificationQueue =>
      prevNotificationQueue.map(notificationProps =>
        notificationProps.notificationId === notificationId ?
          { ...notificationProps, pauseAutoHide: false } : notificationProps
      )
    )
  }

  return (
    <NotificationContext.Provider {...props} value={{
      notificationQueue,
      enqueue,
      dequeue,
      open,
      close,
      pauseAutoHide,
      resumeAutoHide
    }} />
  );
}

export function getSnackbarProps(notificationProps) {
  const { notificationId, action, pauseAutoHide, type, ...snackbarProps } = notificationProps;
  return snackbarProps;
}
