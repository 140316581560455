import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ResumeSectionHeader from "../section-header";
import { EditExperiences } from "./edit-experiences";
import { ConfirmDeleteModal } from "../../../common/confirm-delete-modal";
import { useMutation } from "graphql-hooks";
import { DELETE_EXPERIENCE } from "../../../../graphql/query";
import { ResumeContext } from "../../../../context/resume-context";
import { useNotifications } from '../../../common/notifications';
import PropTypes from 'prop-types';

function Experiences({ experiences }) {
  const classes = useStyles();
  const notifications = useNotifications();

  const [selectedExperience, setSelectedExperience] = useState(null);
  const [deletedExperience, setDeletedExperience] = useState(null);

  const { updateRefreshNeeded } = useContext(ResumeContext);

  const [deleteExperience] = useMutation(DELETE_EXPERIENCE);

  const handleConfirmDelete = async (experience) => {
    console.log(
      `Deleting experience: ${experience.id} - ${experience.company}`
    );
    try {
      await deleteExperience({
        variables: {
          experience_id: experience.id,
        },
      });
      updateRefreshNeeded(true);
      setDeletedExperience(null);
    } catch (error) {
      // TODO set error to global error context
      console.error(error);
    }
  };

  useEffect(() => {
    if (!experiences.length) {
      return;
    }
    const hasProjects = experiences.filter(experience => experience.experiences.length > 0).length;
    if (!hasProjects) {
      notifications.info(
          <>
            <div>Click the <AddIcon className={classes.actionIcon}/> next to
              <br/>the <EditIcon className={classes.actionIcon}/> to add projects!
            </div>
          </>
      );
    }
    // eslint-disable-next-line
  }, [experiences]);

  return (
    <Box mb={3}>
      <ResumeSectionHeader title="Experiences">
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() =>
            setSelectedExperience({
              experience_type: "E",
            })
          }
        >
          add experience
        </Button>
      </ResumeSectionHeader>
      <Box mt={1}>
        {experiences.map((experience) => (
          <React.Fragment key={experience.id}>
            <Box key={experience.id} className={classes.experienceContainer}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} md={4} className={classes.centerSm}>
                  <Box fontWeight="fontWeightMedium">{experience.company}</Box>
                </Grid>
                <Grid item xs={12} md={3} className={classes.centerSm}>
                  <Box>{experience.role}</Box>
                </Grid>
                <Grid item xs={12} md={4} className={classes.centerSm}>
                  <Box>
                    {experience.start_month}/{experience.start_year} -{" "}
                    {experience.current_position
                      ? "current"
                      : `${experience.end_month}/${experience.end_year}`}
                  </Box>
                </Grid>
                <Grid item xs={12} md={1} className={classes.centerSm}>
                  <Box className={classes.buttonBox}>
                    <IconButton
                      color="primary"
                      title="add project"
                      onClick={() =>
                        setSelectedExperience({
                          experience_type: "P",
                          employer_id: experience.id,
                        })
                      }
                    >
                      <AddIcon className={classes.actionIcon} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      title="edit employer"
                      onClick={() => setSelectedExperience(experience)}
                    >
                      <EditIcon className={classes.actionIcon} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      title="delete employer"
                      onClick={() => setDeletedExperience(experience)}
                    >
                      <DeleteOutlineIcon className={classes.actionIcon} />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {experience.experiences.map((projectExp) => (
              <Box key={projectExp.id} className={classes.experienceContainer}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} md={4} className={classes.centerSm}>
                    <Box className={classes.subExperience}>
                      {projectExp.company}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.centerSm}>
                    <Box>
                      {experience.employer_type === "N" && projectExp.role}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.centerSm}>
                    <Box>
                      {projectExp.start_month}/{projectExp.start_year} -{" "}
                      {projectExp.current_position
                        ? "current"
                        : `${projectExp.end_month}/${projectExp.end_year}`}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2} className={classes.centerSm}>
                    <Box className={classes.buttonBox}>
                      <IconButton
                        color="primary"
                        title="edit project"
                        onClick={() => setSelectedExperience(projectExp)}
                      >
                        <EditIcon className={classes.actionIcon} />
                      </IconButton>
                      <IconButton
                        color="primary"
                        title="delete project"
                        onClick={() => setDeletedExperience(projectExp)}
                      >
                        <DeleteOutlineIcon className={classes.actionIcon} />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </React.Fragment>
        ))}
        {selectedExperience && (
          <EditExperiences
            open={Boolean(selectedExperience)}
            experience={selectedExperience}
            handleClose={() => setSelectedExperience(null)}
          />
        )}
        {deletedExperience && (
          <ConfirmDeleteModal
            open={Boolean(deletedExperience)}
            title={`Delete ${deletedExperience.company}?`}
            onConfirmDelete={() => handleConfirmDelete(deletedExperience)}
            onClose={() => setDeletedExperience(null)}
          />
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  experienceContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    "&:hover": {
      background: theme.palette.grey[100],
    },
  },
  actionIcon: {
    fontSize: "1.2rem",
  },
  buttonBox: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  subExperience: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
    },
  },
}));

Experiences.propTypes = {
  experiences: PropTypes.arrayOf(PropTypes.object)
};

export default Experiences;
