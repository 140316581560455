import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
// ^^^ FOR NOW UNTIL MUI v5 is available. (https://github.com/mui-org/material-ui/issues/13394#issuecomment-619266831)

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: { main: "#005596" },
    secondary: { main: "#4597D3"},
    background: {
      default: "#fafafa",
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    fontSize: 18,
  },
});

export default theme;
