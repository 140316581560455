import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import "./index.css";

import Amplify from "aws-amplify";
import config from "./aws-exports";
import { ResumeProvider } from "./context/resume-context";
import { NotificationProvider } from "./context/notification-context";
import { GraphQLProvider } from "./context/graphql-context";

// hack due to https://github.com/aws-amplify/amplify-cli/issues/2792
if (process.env.REACT_APP_LOCAL_ENV) {
  config.oauth.redirectSignIn = `${window.location.origin}/`;
  config.oauth.redirectSignOut = `${window.location.origin}/`;
}

Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <GraphQLProvider url={process.env.REACT_APP_GRAPHQL_ENDPOINT}>
      <NotificationProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <ResumeProvider>
              <CssBaseline />
              <App />
            </ResumeProvider>
          </ThemeProvider>
        </BrowserRouter>
      </NotificationProvider>
    </GraphQLProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
