import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useMutation } from "graphql-hooks";
import uniqueId from "lodash/uniqueId";
import React, { useContext, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { ResumeContext } from "../../../../context/resume-context";
import {
  DELETE_SUMMARY_BULLETS,
  INSERT_RESUME,
  UPSERT_RESUME_INFO,
} from "../../../../graphql/query";
import ResumeModal from "../../../common/modal";

const { useState } = require("react");

const validationSchema = yup.object().shape({
  resume_name: yup.string().required(),
  summary: yup.string().required(),
  summary_bullets: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      bullet_text: yup.string().required("Text is required"),
    })
  ),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  summaryBullet: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-around",
    alignItems: "center",
  },
  deleteButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function EditResumeInfo({ data, open, handleClose, title, isNew = false }) {
  const classes = useStyles();

  const { resume_name, summary, summary_bullets } = data;

  const { control, register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      resume_name,
      summary,
      summary_bullets,
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "summary_bullets",
  });

  const [summaryBulletIdsToDelete, setSummaryBulletIdsToDelete] = useState([]);
  const { updateRefreshNeeded } = useContext(ResumeContext);
  const [upsertResumeInfo] = useMutation(UPSERT_RESUME_INFO);
  const [insertResumeInfo] = useMutation(INSERT_RESUME);
  const [deleteSummaryBullets] = useMutation(DELETE_SUMMARY_BULLETS);

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const removeSummaryBullet = (sb, index) => {
    remove(index);
    if (!sb.id.toString().startsWith("temp_")) {
      setSummaryBulletIdsToDelete([...summaryBulletIdsToDelete, sb.id]);
    }
  };

  const onSubmit = async (formData) => {
    if(!formData.summary_bullets)
      formData.summary_bullets = [];

    if (isNew) {
      const { data: insertedResume } = await insertResumeInfo({
        variables: {
          resume: {
            summary: formData.summary,
            resume_name: formData.resume_name,
            employee_id: data.employee_id,
            summary_bullets: {
              data: formData.summary_bullets.map(({ bullet_text }) => ({
                bullet_text,
              })),
            },
          },
        },
      });
      setSummaryBulletIdsToDelete([]);
      handleClose(insertedResume);
    } else {
      const promises = [];
      if (summaryBulletIdsToDelete.length) {
        promises.push(
          deleteSummaryBullets({
            variables: {
              summary_bullet_ids: summaryBulletIdsToDelete,
            },
          })
        );
      }

      const summary_bullets = formData.summary_bullets
        ? formData.summary_bullets.map((sb) =>
            sb.id.toString().startsWith("temp_") ? { ...sb, id: undefined } : sb
          )
        : [];

      promises.push(
        upsertResumeInfo({
          variables: {
            id: data.id,
            summary: formData.summary,
            resume_name: formData.resume_name,
            employee_id: data.employee_id,
            summary_bullets,
          },
        })
      );

      await Promise.all(promises);
      updateRefreshNeeded(true);
      handleClose();
    }
  };

  return (
    <ResumeModal
      title={title}
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <form>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              name="resume_name"
              label="Name"
              inputRef={register}
              variant="outlined"
              fullWidth
              required
              error={Boolean(errors?.resume_name)}
              helperText={errors?.resume_name?.message}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="summary"
              label="Summary"
              inputRef={register}
              variant="outlined"
              multiline
              fullWidth
              required
              error={Boolean(errors?.summary)}
              helperText={errors?.summary?.message}
              className={classes.textField}
            />
          </Grid>
          {fields.map((sb, index) => (
            <Grid item xs={12} key={sb.id} className={classes.summaryBullet}>
              <Grid item xs={10}>
                <input
                  type="hidden"
                  name={`summary_bullets[${index}].id`}
                  defaultValue={fields[index].id}
                  ref={register()}
                />
                <TextField
                  name={`summary_bullets[${index}].bullet_text`}
                  defaultValue={fields[index].bullet_text}
                  inputRef={register()}
                  variant="outlined"
                  fullWidth
                  required
                  label="Summary Bullet"
                  className={classes.textField}
                  error={Boolean(
                    errors.summary_bullets &&
                      errors.summary_bullets[index]?.bullet_text
                  )}
                  helperText={
                    errors.summary_bullets &&
                    errors.summary_bullets[index]?.bullet_text?.message
                  }
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                className={classes.deleteButtonContainer}
              >
                <IconButton
                  color="default"
                  onClick={() => removeSummaryBullet(fields[index], index)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() =>
              append({
                id: uniqueId("temp_"),
                bullet_text: "",
              })
            }
          >
            add summary bullet
          </Button>
        </Grid>
      </form>
    </ResumeModal>
  );
}

export default EditResumeInfo;
