import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

function getFreeSoloValue(key) {
  return (obj) => {
    if (!obj) {
      return "";
    }
    return typeof obj === "string" ? obj : obj[key];
  };
}

function toggleCheckboxGroupValue(groupValue, checkboxId, checked) {
  const prevChecked = !!groupValue.find((x) => x === checkboxId);
  let newGroupValue = groupValue;

  if (checked && !prevChecked) {
    newGroupValue = [...groupValue, checkboxId];
  } else if (!checked && prevChecked) {
    newGroupValue = groupValue.filter((x) => x !== checkboxId);
  }

  return newGroupValue;
}

const SearchFilterPanel = (props) => {
  const classes = useStyles();
  const {
    disabled,
    enterprises,
    locations,
    onParamsChange,
    params,
    skills,
    toolsUsed,
    companies,
    experience,
    positions,
    roles,
    searchLabel,
    experienceSearchLabel,
    showSkills,
    showToolsUsed,
    showCompanies,
    showEnterprises,
    showLocations,
    showPositions,
    showRoles,
  } = props;

  const [searchValue, setSearchValue] = useState(params.search);
  const [skillsValue, setSkillsValue] = useState(params.skills);
  const [experienceValue, setExperienceValue] = useState(params.experience);
  const [toolsValue, setToolsUsedValue] = useState(params.toolsUsed);
  const [companiesValue, setCompaniesValue] = useState(params.companies);
  const [enterprisesValue, setEnterprisesValue] = useState(params.enterprises);
  const [locationsValue, setLocationsValue] = useState(params.locations);
  const [positionsValue, setPositionsValue] = useState(params.positions);
  const [rolesValue, setRolesValue] = useState(params.roles);

  useEffect(() => {
    setSearchValue(params.search);
    setSkillsValue(params.skills);
    setExperienceValue(params.experience)
    setToolsUsedValue(params.toolsUsed)
    setCompaniesValue(params.companies)
    setEnterprisesValue(params.enterprises);
    setLocationsValue(params.locations);
    setPositionsValue(params.positions);
    setRolesValue(params.roles);
  }, [params]);

  const handleSearchChange = useCallback(
    (event) => {
      const search = event.target.value || "";
      setSearchValue(search);
      onParamsChange({ search });
    },
    [setSearchValue, onParamsChange]
  );

  const handleSkillsChange = useCallback(
    (event, value) => {
      const newValue = value.map(getFreeSoloValue("label"));
      setSkillsValue(newValue);
      onParamsChange({ skills: newValue });
    },
    [setSkillsValue, onParamsChange]
  );

  const handleToolsUsedChange = useCallback(
      (event, value) => {
        const newValue = value.map(getFreeSoloValue("tech_tools_bullet"));
        setToolsUsedValue(newValue);
        onParamsChange({ toolsUsed: newValue });
      },
      [setToolsUsedValue, onParamsChange]
  );

    const handleCompaniesChange = useCallback(
        (event, value) => {
            const newValue = value.map(getFreeSoloValue("companies"));
            setCompaniesValue(newValue);
            onParamsChange({ companies: newValue });
        },
        [setCompaniesValue, onParamsChange]
    );

    const handleExperienceChange = useCallback(
        (event) => {
            const experienceValue = event.target.value || "";
            setExperienceValue(experienceValue);
            onParamsChange({ experience: experienceValue });
        },
        [setExperienceValue, onParamsChange]
    );

  const handleEnterpriseChange = useCallback(
      (value, checked) => {
        const newValue = toggleCheckboxGroupValue(enterprisesValue, value, checked);
        setEnterprisesValue(newValue);
        onParamsChange({ enterprises: newValue });
      },
      [enterprisesValue, setEnterprisesValue, onParamsChange]
  );

  const handleLocationChange = useCallback(
    (value, checked) => {
      const newValue = toggleCheckboxGroupValue(locationsValue, value, checked);
      setLocationsValue(newValue);
      onParamsChange({ locations: newValue });
    },
    [locationsValue, setLocationsValue, onParamsChange]
  );

  const handlePositionChange = useCallback(
    (value, checked) => {
      const newValue = toggleCheckboxGroupValue(positionsValue, value, checked);
      setPositionsValue(newValue);
      onParamsChange({ positions: newValue });
    },
    [positionsValue, setPositionsValue, onParamsChange]
  );

  const handleRoleChange = useCallback(
    (value, checked) => {
      const newValue = toggleCheckboxGroupValue(rolesValue, value, checked);
      setRolesValue(newValue);
      onParamsChange({ roles: newValue });
    },
    [rolesValue, setRolesValue, onParamsChange]
  );

  return (
    <>
      <Box>
        <TextField
          label="Name"
          placeholder={searchLabel}
          fullWidth
          variant="outlined"
          onChange={handleSearchChange}
          disabled={disabled}
          value={searchValue}
        />
      </Box>
      {showSkills && (
        <Box mt={3}>
          <Autocomplete
            multiple
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            options={skills || []}
            getOptionLabel={getFreeSoloValue("label")}
            onChange={handleSkillsChange}
            value={skillsValue || []}
            renderInput={(params) => (
              <TextField {...params} label="Skills" variant="outlined" />
            )}
          />
        </Box>
      )}
      {showToolsUsed && (
          <Box mt={3}>
            <Autocomplete
                multiple
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                options={toolsUsed || []}
                getOptionLabel={getFreeSoloValue("tech_tools_bullet")}
                onChange={handleToolsUsedChange}
                value={toolsValue || []}
                renderInput={(params) => (
                    <TextField {...params} label="Tools Used" variant="outlined" />
                )}
            />
          </Box>
      )}
      {showCompanies && (
          <Box mt={3}>
              <Autocomplete
                  multiple
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  freeSolo
                  options={companies || []}
                  getOptionLabel={getFreeSoloValue("company")}
                  onChange={handleCompaniesChange}
                  value={companiesValue || []}
                  renderInput={(params) => (
                      <TextField {...params} label="Companies" variant="outlined" />
                  )}
              />
          </Box>
      )}
      <Box mt={3}>
          <TextField
              label="Keyword"
              placeholder={experienceSearchLabel}
              fullWidth
              options={experience || ""}
              variant="outlined"
              onChange={handleExperienceChange}
              disabled={disabled}
              value={experienceValue}
          />
      </Box>
      {showEnterprises && (
          <Box mt={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.legend}>
                Enterprise
              </FormLabel>
              <FormGroup>
                {enterprises.map((enterprise) => (
                    <FormControlLabel
                        key={enterprise.id}
                        label={enterprise.enterprise_name}
                        control={
                          <Checkbox
                              onChange={(event) => {
                                handleEnterpriseChange(enterprise.id, event.target.checked);
                              }}
                              disabled={disabled}
                              name={enterprise.enterprise_name}
                              checked={!!enterprisesValue?.includes(enterprise.id)}
                          />
                        }
                    />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
      )}
      {showLocations && (
          <Box mt={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.legend}>
                Office
              </FormLabel>
              <FormGroup>
                {locations.map((location) => {
                  const locationKey = `${location.city}-${location.state}`;
                  return (
                      <FormControlLabel
                          key={locationKey}
                          label={`${location.city}, ${location.state}`}
                          control={
                            <Checkbox
                                onChange={(event) => {
                                  handleLocationChange(location, event.target.checked);
                                }}
                                disabled={disabled}
                                name={locationKey}
                                checked={!!locationsValue?.find(locationValue =>
                                    locationValue.city === location.city &&
                                    locationValue.state === location.state)}
                                color="secondary"
                            />
                          }
                      />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Box>
      )}
      {showPositions && (
        <Box mt={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.legend}>
              Positions
            </FormLabel>
            <FormGroup>
              {positions.map((position) => (
                <FormControlLabel
                  key={position.id}
                  label={position.title}
                  control={
                    <Checkbox
                      onChange={(event) => {
                        handlePositionChange(position.id, event.target.checked);
                      }}
                      disabled={disabled}
                      name={position.title}
                      checked={!!positionsValue?.includes(position.id)}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      )}
      {showRoles && (
        <Box mt={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.legend}>
              Roles
            </FormLabel>
            <FormGroup>
              {roles.map((role) => (
                <FormControlLabel
                  key={role.id}
                  label={role.authority}
                  control={
                    <Checkbox
                      onChange={(event) => {
                        handleRoleChange(role.id, event.target.checked);
                      }}
                      disabled={disabled}
                      name={role.authority}
                      checked={!!rolesValue?.includes(role.id)}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  legend: {
    marginBottom: theme.spacing(1),
  },
}));

SearchFilterPanel.propTypes = {
  disabled: PropTypes.bool,
  params: PropTypes.object.isRequired,
  onParamsChange: PropTypes.func.isRequired,
  skills: PropTypes.array,
  toolsUsed: PropTypes.array,
  companies: PropTypes.array,
  enterprises: PropTypes.array,
  locations: PropTypes.array,
  positions: PropTypes.array,
  roles: PropTypes.array,
  searchLabel: PropTypes.string,
  showSkills: PropTypes.bool,
  showToolsUsed: PropTypes.bool,
  showCompanies: PropTypes.bool,
  showEnterprise: PropTypes.bool,
  showLocations: PropTypes.bool,
  showPositions: PropTypes.bool,
  showRoles: PropTypes.bool,
};

export default SearchFilterPanel;
