import { CircularProgress, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

const timeout = { exit: 0, appear: 250, enter: 250 };

const LoaderOverlay = React.memo((props) => {
  const { showSpinner, showOverlay, color = "white", size = "default" } = props;
  const classes = useStyles();
  const overlayColor =
    color === "white" ? "rgba(255,255,255,0.5)" : "rgba(0,0,0,0.05)";
  const progressSize = size === "small" ? 20 : 40;

  return (
    <>
      <Fade in={showOverlay} timeout={timeout}>
        <div
          className={classes.container}
          style={{ backgroundColor: overlayColor }}
        ></div>
      </Fade>
      <Fade in={showSpinner} timeout={timeout}>
        <div className={classes.container}>
          <CircularProgress size={progressSize} />
        </div>
      </Fade>
    </>
  );
});

LoaderOverlay.propTypes = {
  showSpinner: PropTypes.bool,
  showOverlay: PropTypes.bool,
  color: PropTypes.oneOf(["gray", "white"]),
  size: PropTypes.oneOf(["default", "small"])
};

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    minHeight: 50,
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

export default LoaderOverlay;
