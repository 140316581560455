import React, { useContext, useState } from "react";
import { Box, Grid, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useMutation } from "graphql-hooks";

import ResumeSectionHeader from "../section-header";
import { EditCertificationAndTraining } from "./edit-cert-training";
import { ConfirmDeleteModal } from "../../../common/confirm-delete-modal";
import { DELETE_CERTIFICATION_TRAINING } from "../../../../graphql/query";
import { ResumeContext } from "../../../../context/resume-context";
import { certTypes } from "./cert-types";

function CertificationAndTraining({ certificationTrainings }) {
  const classes = useStyles();
  const { updateRefreshNeeded } = useContext(ResumeContext);
  const [deleteCertTraining] = useMutation(DELETE_CERTIFICATION_TRAINING);

  const [selectedCertTraining, setSelectedCertTraining] = useState(null);
  const [deletedCertTraining, setDeletedCertTraining] = useState(null);

  const handleConfirmDelete = async (certTraining) => {
    try {
      await deleteCertTraining({
        variables: {
          id: certTraining.id,
        },
      });
      updateRefreshNeeded(true);
      setDeletedCertTraining(null);
    } catch (error) {
      // TODO set error to global error context
      console.error(error);
    }
  };

  return (
    <Box mb={3}>
      <ResumeSectionHeader title="Certification &amp; Training">
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setSelectedCertTraining({})}
        >
          add certification / training
        </Button>
      </ResumeSectionHeader>
      <Box mt={1}>
        {/* ---- CERT/TRAINING (start) -------------------- */}
        {certificationTrainings.map((ct) => (
          <Box className={classes.trainingContainer} key={ct.id}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} md={4}>
                <Box fontWeight="fontWeightMedium">
                  {ct.certification_training_name}
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box>{certTypes.get(ct.type)}</Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box>
                  {ct.certification_training_month}/
                  {ct.certification_training_year}
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box className={classes.buttonBox}>
                  <IconButton
                    color="primary"
                    title="edit certification / training"
                    onClick={() => setSelectedCertTraining(ct)}
                  >
                    <EditIcon className={classes.actionIcon} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    title="delete certification / training"
                    onClick={() => setDeletedCertTraining(ct)}
                  >
                    <DeleteOutlineIcon className={classes.actionIcon} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
        {selectedCertTraining && (
          <EditCertificationAndTraining
            open={Boolean(selectedCertTraining)}
            certTraining={selectedCertTraining}
            handleClose={() => setSelectedCertTraining(null)}
          />
        )}
        {deletedCertTraining && (
          <ConfirmDeleteModal
            open={Boolean(deletedCertTraining)}
            title={`Delete ${deletedCertTraining.certification_training_name}?`}
            onConfirmDelete={() => handleConfirmDelete(deletedCertTraining)}
            onClose={() => setDeletedCertTraining(null)}
          />
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  trainingContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    "&:hover": {
      background: theme.palette.grey[100],
    },
  },
  actionIcon: {
    fontSize: "1.2rem",
  },
  buttonBox: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));
export default CertificationAndTraining;
