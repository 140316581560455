/** QUERIES **/
export const EMPLOYEE_BY_ID = `
query EmployeeById($employeeId: bigint!) {
  employee_by_pk(employeeid: $employeeId) {
    firstname
    lastname
    position {
      title
    }
  }
}
`;

export const RESUMES_BY_USER = `
query ResumesByEmployee($employeeId: bigint) {
    resume(where: { employee_id: { _eq: $employeeId } }) {
      id
      resume_name
      summary
      update_date
    }
  }
`;

export const RESUME_BY_ID = `
  query ResumeById($resumeId: bigint!) {
    resume_by_pk(id: $resumeId) {
      id
      employee_id
      resume_name
      summary
      summary_bullets {
        id
        bullet_text
      }
      employee {
        employeeid
        firstname
        middleinitial
        lastname
        email
        position {
          title
        }
        enterprise_manager {
          employee {
            firstname
            lastname
          }
        }
      }
      resume_categories(order_by: { sort_order: asc }) {
        id
        label
        resume_category_skills(order_by: { sort_order: asc }) {
          skill_id
          label
        }
      }
      experiences(
        order_by: {
          end_year: desc_nulls_first
          end_month: desc_nulls_first
          start_year: desc
          start_month: desc
        }
        where: { experience_type: { _eq: "E" } }
      ) {
        id
        employer_id
        employer_type
        company
        current_position
        role
        start_month
        start_year
        end_month
        end_year
        experience_type
        experience_bullets {
          id
          bullet_text
        }
        experiences(
          order_by: { 
            end_year: desc_nulls_first
            end_month: desc_nulls_first
            start_year: desc
            start_month: desc 
          }
          where: { experience_type: { _eq: "P" } }
        ) {
          id
          employer_id
          company
          current_position
          industry_description
          role
          start_month
          start_year
          tech_tools_bullet
          end_year
          end_month
          experience_type
          experience_bullets {
            id
            bullet_text
          }
        }
      }
      education {
        id
        additional_desc
        city
        country
        degree
        graduated
        graduation_month
        graduation_year
        major
        minor
        school
        state
      }
      certification_trainings {
        id
        certification_training_month
        certification_training_name
        certification_training_year
        training_provider
        type
      }
    }
  }
`;

// used when we are fetching a resume and don't need all the nested ids (mainly for copying a resume)

// location {
//   city
//   state
//   zip
//   address_line1
//   address_line2
//   phone_number
// }
export const RESUME_BY_ID_SANS_ID = `
  query ResumeById($resumeId: bigint!) {
    resume_by_pk(id: $resumeId) {
      id
      employee_id
      resume_name
      summary
      summary_bullets {
        bullet_text
      }
      employee {
        employeeid
        firstname
        middleinitial
        lastname
        email
        position {
          title
        }
      }
      resume_categories(order_by: { sort_order: asc }) {
        label
        resume_category_skills(order_by: { sort_order: asc }) {
          skill_id
          label
          sort_order
        }
      }
      experiences(
        order_by: {
          end_year: desc_nulls_first
          end_month: desc_nulls_first
          start_year: desc
          start_month: desc
        }
        where: { experience_type: { _eq: "E" } }
      ) {
        employer_id
        employer_type
        company
        current_position
        role
        start_month
        start_year
        end_month
        end_year
        experience_type
        experience_bullets {
          bullet_text
        }
        experiences(
          order_by: { end_year: desc_nulls_first }
          where: { experience_type: { _eq: "P" } }
        ) {
          employer_id
          company
          current_position
          industry_description
          role
          start_month
          start_year
          tech_tools_bullet
          end_year
          end_month
          experience_type
          experience_bullets {
            bullet_text
          }
        }
      }
      education {
        additional_desc
        city
        country
        degree
        graduated
        graduation_month
        graduation_year
        major
        minor
        school
        state
      }
      certification_trainings {
        certification_training_month
        certification_training_name
        certification_training_year
        training_provider
        type
      }
    }
  }
`;

export const ALL_ROLES = `
query AllRoles {
  role(order_by: {id: asc}) {
    id
    authority
  }
}
`;

export const ALL_ENTERPRISES = `
query AllEnterprises {
  enterprise(order_by: {id: asc}) {
    id
    enterprise_name
  }
}
`;

export const ALL_LOCATIONS = `
query AllLocations {
  enterprise_manager(distinct_on: [city, state], order_by: {city: asc, state: asc}) {
    city
    state
  }
}
`;

export const ALL_MANAGERS = `
query AllManagers {
  enterprise_manager {
    id
    city
    state
    employee {
      firstname
      lastname
    }
  }
}
`;

export const ALL_POSITIONS = `
query AllPositions {
  position(order_by: {title: asc}) {
    id
    title
  }
}
`;

export const ALL_SKILLS = `
query AllSkills {
  skill(order_by: {label: asc}) {
    id
    label
  }
}
`;

export const ALL_TOOLS_USED = `
query AllToolsUsed {
  experience(where: { _and: [{ tech_tools_bullet: { _is_null: false } }, { tech_tools_bullet: { _neq: "" } }] }) {
    tech_tools_bullet
  }
}
`;

export const ALL_COMPANIES = `
query AllCompanies {
  experience(where: {_and: [{company: {_is_null: false}}, {company: {_neq: ""}}]}) {
    company
  }
}
`;

export const SKILLS_BY_CATEGORY = `
query SkillsByCategory($skillCategoryId: bigint!) {
    skill(
      where: { skill_category_id: { _eq: $skillCategoryId } }
      order_by: { label: asc }
    ) {
      id
      label
    }
  }
`;

export const ALL_EMPLOYEES = `
query AllEmployees {
    employee(order_by: { lastname: asc }) {
      employeeid
      firstname
      lastname
      manager_id
    }
  }
`;

/**
 * Generate query params for use with EMPLOYEE_SEARCH
 */
export const getEmployeeSearchVariables = ({
  search,
  enterprises,
  locations,
  positions,
  roles,
  limit,
  offset,
  order_by,
}) => {
  const variables = { limit, offset, order_by };
  const hasFilters = !!(
    search ||
    enterprises?.length ||
    locations?.length ||
    positions?.length ||
    roles?.length
  );

  if (hasFilters) {
    variables.where = { "_and": [] };
    if (search) {
      search = search.split(" ");

      let whereClause = '';
      if (search.length === 1) {
          whereClause = {_or:[{ firstname: {_ilike: `%${search[0]}%`}},{ lastname: {_ilike: `%${search[0]}%`}},]};
      }
      if (search.length === 2) {
          whereClause = {_and:[{ firstname: {_ilike: `%${search[0]}%`}},{ lastname: {_ilike: `%${search[1]}%`}},]};
      }
      if (search.length >= 3) {
          whereClause = {_and:[{ firstname: {_ilike: `%${search[0]}%`}},{ middleinitial: {_ilike: `%${search[1]}%`}},{ lastname: {_ilike: `%${search[2]}%`}},]};
      }

      variables.where._and.push(whereClause);
    }

    if (enterprises?.length) {
      variables.where._and.push({
        _or: enterprises.map(enterprise => ({
          location_id: {_eq: enterprise}
        }))
      });
    }

    if (locations?.length) {
      variables.where._and.push({enterprise_manager: { _or: locations.map(location => ({
          city: { _eq: location.city },
          state: { _eq: location.state }
        }))
      }});
    }

    if (positions?.length) {
      variables.where._and.push({ position_id: { _in: positions } });
    }

    if (roles?.length) {
      variables.where._and.push({ employee_roles: { role_id: { _in: roles } } });
    }
  }

  return variables;
};

export const EMPLOYEE_SEARCH = `
query SearchAllEmployees ($limit: Int!, $offset: Int!, $order_by: [employee_order_by!]!, $where: employee_bool_exp)
{
  employee (
    limit: $limit
    offset: $offset
    where: $where
    order_by: $order_by
  ) {
    employeeid
    firstname
    lastname
    middleinitial
    manager_id
    position_id
    email
    employee_roles {
      role_id
    }
    employee_date_histories {
      employee_date_history_id
      begin_date
      end_date
    }
  }
  employee_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
`;

export const INSERT_EMPLOYEE = `
mutation InsertEmployee($employee: employee_insert_input!) {
  insert_employee_one(object: $employee) {
    employeeid
  }
}`;

export const DELETE_EMPLOYEE = `
mutation DeleteEmployee($employeeId: bigint!) {
  delete_employee_role(where: {employee_id: {_eq: $employeeId}}) { affected_rows }
  delete_employee_date_history(where: {employee_id: {_eq: $employeeId}}) { affected_rows }
  delete_resume(where: {employee_id: {_eq: $employeeId}}) { affected_rows }
  delete_employee_by_pk(employeeid: $employeeId) { employeeid }
}`;

export const UPDATE_DATE_HISTORY = `
mutation UpdateDateHistory($employeeDateInfo: employee_date_history_insert_input!){
insert_employee_date_history_one (
    object: $employeeDateInfo
    on_conflict: {
      constraint: employee_date_history_pkey
      update_columns: [
        employee_id
        begin_date
        end_date
        ]
    }
    ){
       employee_date_history_id
       employee_id
       begin_date
       end_date
    }
}`;

export const UPDATE_EMPLOYEE = `
mutation UpdateEmployee($employeeId: bigint!, $employeeInfo: employee_set_input!, $roleId: bigint!) {
  update_employee_by_pk(pk_columns: { employeeid: $employeeId }, _set: $employeeInfo) {
    employeeid
  }
  update_employee_role(
    where: {employee_id:{_eq: $employeeId}},
    _set: {role_id: $roleId}
  ) {
    affected_rows
  }
}
`;

export const getResumeSearchVariables = ({
  search,
  experience,
  companies,
  skills,
  toolsUsed,
  enterprises,
  locations,
  limit,
  offset,
}) => {
  const variables = { limit, offset };

  if (search || experience || skills?.length || locations?.length || enterprises?.length || toolsUsed?.length || companies?.length) {
    variables.whereEmployees = { _and: [] };
  }

  if (search) {
    search = search.split(" ");

    let whereClause = '';
    if (search.length === 1) {
        whereClause = {_or:[{ firstname: {_ilike: `%${search[0]}%`}},{ lastname: {_ilike: `%${search[0]}%`}},]};
    }
    if (search.length === 2) {
        whereClause = {_and:[{ firstname: {_ilike: `%${search[0]}%`}},{ lastname: {_ilike: `%${search[1]}%`}},]};
    }
    if (search.length >= 3) {
        whereClause = {_and:[{ firstname: {_ilike: `%${search[0]}%`}},{ middleinitial: {_ilike: `%${search[1]}%`}},{ lastname: {_ilike: `%${search[2]}%`}},]};
    }

    variables.whereEmployees._and.push(whereClause);

  }

  if (skills?.length) {
    const skillNameQuery = {
      _or: skills.map((name) => ({ label: { _ilike: `%${name}%` } })),
    };
    variables.whereEmployees._and.push({
      resumes: { resume_skills: { skill: skillNameQuery } },
    });
    
    variables.whereResumes = { resume_skills: { skill: skillNameQuery } };
    variables.whereSkills = { skill: skillNameQuery };
    variables.skillLimit = 20;
  } else {
    variables.skillLimit = 0;
  }

  if (toolsUsed?.length) {
    const toolsKeywordOrQuery = toolsUsed.map((name) => ({ tech_tools_bullet: { _ilike: `%${name.trim()}%` } }))
    const toolKeywordQuery = {
      _or: toolsKeywordOrQuery,
    };
    variables.whereEmployees._and.push({
      resumes: { experiences: toolKeywordQuery },
    });

    if(variables.whereResumes?.experiences?._or) {
      toolsKeywordOrQuery.forEach(toolUsedOr => {
        variables.whereResumes._or.push(toolUsedOr)
      })
    } else {
      variables.whereResumes = { experiences: toolKeywordQuery };
    }

    if(variables.whereExperiences?._or) {
      toolsKeywordOrQuery.forEach(toolUsedOr => {
        variables.whereExperiences._or.push(toolUsedOr)
      })
    } else {
      variables.whereExperiences = toolKeywordQuery;
    }
  }

  if(experience) {

    const experienceKeywordOrQuery = { experience_bullets: {bullet_text: { _ilike: `%${experience}%` } }}
    const experienceKeywordQuery = {
      _or: experienceKeywordOrQuery,
    };
    variables.whereEmployees._and.push({
      resumes: { experiences: experienceKeywordQuery },
    });

    if(variables.whereResumes?.experiences?._or) {
      variables.whereResumes.experiences._or.push(experienceKeywordOrQuery)
    } else {
      variables.whereResumes = { experiences: experienceKeywordQuery };
    }

    if(variables.whereExperiences?._or) {
      variables.whereExperiences._or.push(experienceKeywordOrQuery)
    } else {
      variables.whereExperiences = experienceKeywordQuery;
    }
  }

  if(companies?.length) {
    const companyKeywordOrQuery = companies.map((name) => ({ company: { _ilike: `%${name.trim()}%` } }))
    const companyKeywordQuery = {
      _or: companyKeywordOrQuery,
    };
    variables.whereEmployees._and.push({
      resumes: { experiences: companyKeywordQuery },
    });

    if(variables.whereResumes?.experiences?._or) {
      companyKeywordOrQuery.forEach(companyKeywordOr => {
        variables.whereResumes.experiences._or.push(companyKeywordOr)
      })
    } else {
      variables.whereResumes = { experiences: companyKeywordQuery };
    }

    if(variables.whereExperiences?._or) {
      companyKeywordOrQuery.forEach(companyKeywordOr => {
        variables.whereExperiences._or.push(companyKeywordOr)
      })
    } else {
      variables.whereExperiences = companyKeywordQuery;
    }
  }

  if(experience || toolsUsed?.length || companies?.length) {
    variables.experienceLimit = 40;
  } else {
    variables.experienceLimit = 0;
  }

  if (locations?.length) {
    variables.whereEmployees._and.push({enterprise_manager: { _or: locations.map(location => ({
        city: { _eq: location.city },
        state: { _eq: location.state }
      }))
    }});
  }

  if (enterprises?.length) {
    variables.whereEmployees._and.push({
        _or: enterprises.map(enterprise => ({
          location_id: {_eq: enterprise}
        }))
      });
  }

  return variables;
};

export const RESUME_SEARCH = `
query ResumeSearch($limit: Int!, $offset: Int!, $whereResumes: resume_bool_exp, $whereEmployees: employee_bool_exp, $whereSkills: resume_skill_bool_exp, $whereExperiences: experience_bool_exp, $skillLimit: Int!, $experienceLimit: Int!) {
    employee(
      limit: $limit
      offset: $offset
      where: $whereEmployees
      order_by: { lastname: asc }
    ) {
      employeeid
      firstname
      lastname
      employee_date_histories {
        end_date
      }
      resumes(
        where: $whereResumes
      ) {
        update_date
        id
        resume_name
        summary
        experiences (
          where: $whereExperiences
          limit: $experienceLimit
        ) {
            experience_bullets {
              bullet_text
          }
          company
          tech_tools_bullet
        }
        resume_skills(
          where: $whereSkills
          limit: $skillLimit
        ) {
          skill {
            id
            label
          }
        }
      }
    }
    employee_aggregate(where: $whereEmployees) {
      aggregate {
        count
      }
    }
  }
`;

/** MUTATIONS **/

export const CREATE_SKILLS = `
mutation CreateSkills($skills: [skill_insert_input!]!) {
  insert_skill(objects: $skills) {
    affected_rows
    returning {
      id
      label
    }
  }
}`;

export const CREATE_RESUME_SKILLS = `
mutation CreateResumeSkills($resumeSkills: [resume_skill_insert_input!]!) {
    insert_resume_skill(objects: $resumeSkills) {
      affected_rows
    }
  }
`;

export const DELETE_RESUME_SKILLS = `
mutation DeleteResumeSkills($resumeId: bigint!, $skillIds: [bigint!]!) {
    delete_resume_skill(
      where: {
        _and: { resume_id: { _eq: $resumeId }, skill_id: { _in: $skillIds } }
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_RESUME_SKILLS_BY_CATEGORY = `
mutation DeleteResumeSkills($resumeId: bigint!, $skillCategoryId: bigint!) {
  delete_resume_skill(where: {
    _and: {
      resume_id: {_eq: $resumeId},
      skill: {
        skill_category_id: {_eq: $skillCategoryId}}}}) {
    affected_rows
  }
}
`;

export const INSERT_RESUME = `
mutation InsertResumeInfo($resume: resume_insert_input!) {
  insert_resume_one(object: $resume) {
    id
    resume_name
    summary
    employee_id
    summary_bullets {
      id
      resume_id
      bullet_text
    }
    education {
      id
      additional_desc
      city
      country
      degree
      graduated
      graduation_month
      graduation_year
      major
      minor
      school
      state
    }
    experiences {
      id
      employer_id
      company
      current_position
      industry_description
      role
      start_month
      start_year
      tech_tools_bullet
      end_year
      end_month
      experience_type
      experience_bullets {
        bullet_text
      }
      experiences {
        id
        employer_id
        company
        current_position
        industry_description
        role
        start_month
        start_year
        tech_tools_bullet
        end_year
        end_month
        experience_type
        experience_bullets {
          bullet_text
        }
      }
    }
    certification_trainings {
      id
      certification_training_month
      certification_training_name
      certification_training_year
      training_provider
      type
    } 
    resume_categories {
      id
      label
      resume_category_skills {
        skill_id
        label
      }
    }
    
  }
}`;

export const DELETE_RESUME = `
mutation MyMutation($resumeId: bigint!) {
  delete_resume_by_pk(id: $resumeId) {
    id
  }
}`;

export const UPSERT_RESUME_INFO = `
mutation UpsertResumeInfo($id: bigint!, $employee_id: bigint!, $resume_name: String, $summary: String, $summary_bullets: [summary_bullet_insert_input!]!) {
  insert_resume_one(object: {
    id: $id,
    resume_name: $resume_name,
    summary: $summary,
    employee_id: $employee_id,
    summary_bullets: {
      data:$summary_bullets,
      on_conflict: {
        constraint: summary_bullet_pkey,
        update_columns:[
          id
          bullet_text
        ]
      }
    }
  },
  on_conflict:{
    constraint:resume_pkey,
    update_columns: [
      resume_name
      summary
    ]
  }) {
    id
    resume_name
    summary
    employee_id
    summary_bullets {
      id
      resume_id
      bullet_text
    }
  }
}`;

export const CREATE_EXPERIENCES = `
mutation CreateExperiences($experiences: [experience_insert_input!]!) {
  insert_experience(objects: $experiences) {
    affected_rows
  }
}`;

export const UPDATE_EXPERIENCE = `
mutation UpdateExperience($id: bigint!, $experience: experience_set_input) {
  update_experience(where: {id:{_eq: $id}}, _set: $experience) {
    returning {
      id
    }
  }
}`;

export const DELETE_EXPERIENCE = `
mutation DeleteExperience($experience_id: bigint!) {
  delete_experience(where: {id: {_eq: $experience_id}}) {
    affected_rows
  }
} `;

export const CREATE_EDUCATION = `
mutation CreateEducation($education: education_insert_input!) {
  insert_education_one(object: $education) {
    id
  }
}`;

export const UPDATE_EDUCATION = `
mutation UpdateEducation($id: bigint!, $education: education_set_input) {
  update_education(where: {id:{_eq: $id}}, _set: $education) {
    returning {
      id
    }
  }
}`;

export const DELETE_EDUCATION = `
mutation DeleteEducation($education_id: bigint!) {
  delete_education(where: {id: {_eq: $education_id}}) {
    affected_rows
  }
} `;

export const CREATE_CERTIFICATION_TRAINING = `
mutation CreateCertificationTraining($cert_training: certification_training_insert_input!) {
  insert_certification_training_one(object: $cert_training) {
    id
  }
}`;

export const UPDATE_CERTIFICATION_TRAINING = `
mutation UpdateCertificationTraining($id: bigint!, $cert_training: certification_training_set_input) {
  update_certification_training(where: {id:{_eq: $id}}, _set: $cert_training) {
    returning {
      id
    }
  }
}`;

export const DELETE_CERTIFICATION_TRAINING = `
mutation DeleteCertificationTraining($id: bigint!) {
  delete_certification_training(where: {id: {_eq: $id}}) {
    affected_rows
  }
} `;

export const DELETE_SUMMARY_BULLETS = `
mutation DeleteSummaryBullets($summary_bullet_ids: [bigint!]!) {
  delete_summary_bullet(where: {id: {_in: $summary_bullet_ids}}) {
    affected_rows
  }
}`;

export const DELETE_EXPERIENCE_BULLETS = `
mutation DeleteExperienceBullets($experience_bullet_ids: [bigint!]!) {
  delete_experience_bullet(where: {id: {_in: $experience_bullet_ids}}) {
    affected_rows
  }
}`;

export const UPSERT_EXPERIENCE = `
mutation UpsertExperience( $experience: experience_insert_input! ) {
  insert_experience_one(
    object: $experience
    on_conflict: {
      constraint: experience_pkey
      update_columns: [
        company
        current_position
        end_month
        end_year
        experience_type
        employer_id
        industry_description
        internal_project
        resume_id
        role
        start_month
        start_year
        employer_type
        tech_tools_bullet
      ]
    }
  ) {
    id
    company
    current_position
    end_month
    end_year
    experience_type
    employer_id
    industry_description
    internal_project
    resume_id
    role
    start_month
    start_year
    employer_type
    tech_tools_bullet
    experience_bullets {
      id
      bullet_text
    }
  }
}`;
