import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

function ResumeModal({
  actionButtonName = "save",
  children,
  disabled = false,
  fullWidth = true,
  handleClose = () => {},
  handleSubmit,
  open = false,
  title = "",
  customActions,
}) {
  const classes = useStyles();
  return (
    <Dialog
      aria-labelledby="resume-dialog-title"
      fullWidth={fullWidth}
      maxWidth="lg"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle
        id="resume-dialog-title"
        className={classes.title}
        onClose={handleClose}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Box my={1}>{children}</Box>
      </DialogContent>
      <DialogActions mt={2}>
        {customActions}
        <Button
          className={classes.button}
          disabled={disabled}
          onClick={handleClose}
        >
          cancel
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handleSubmit}
        >
          {actionButtonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ResumeModal.propTypes = {
  actionButtonName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  customActions: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default ResumeModal;
