import { Box, Container, Tab, Tabs } from "@material-ui/core";
import React, { useContext } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { ResumeContext } from "../context/resume-context";
import Employees from "./employees";
import MyResumes from "./my-resumes";
import ImprovingResumes from "./improving-resumes";

function TabbedContainer() {
  const { userRole } = useContext(ResumeContext);

  return (
    <Container maxWidth="xl">
      <Box mt={3} mx={3}>
        <Route
          path="/"
          render={({ location }) => (
            <>
              <Tabs
                value={location.pathname}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  label="My Resumes"
                  component={Link}
                  to="/"
                  value="/"
                />
                <Tab
                  label="Search Resumes"
                  component={Link}
                  to="/search-resumes"
                  value="/search-resumes"
                />
                {userRole === "ROLE_SUPERVISOR" && (
                  <Tab
                    label="Administration"
                    component={Link}
                    to="/employees"
                    value="/employees"
                  />
                )}
              </Tabs>
              <Switch>
                <Route path="/search-resumes">
                  <ImprovingResumes />
                </Route>
                {userRole === "ROLE_SUPERVISOR" && (
                  <Route path="/employees">
                    <Employees />
                  </Route>
                )}
                <Route path="/">
                  <MyResumes />
                </Route>
              </Switch>
            </>
          )}
        />
      </Box>
    </Container>
  );
}

export default TabbedContainer;
