import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

function Header({ children }) {
  const classes = useStyles();

  return (
    <Box
      borderTop={1}
      borderBottom={1}
      marginBottom={5}
      fontSize="h5.fontSize"
      className={classes.header}
    >
      {children}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderColor: theme.palette.grey[400],
  },
}));

export default Header;
