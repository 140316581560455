import {
  ButtonGroup,
  Button,
  Box,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PublishIcon from "@material-ui/icons/Publish";
import React, { useState, useRef } from "react";
import { useManualQuery } from "graphql-hooks";
import { RESUME_BY_ID } from "../../graphql/query";
import LoaderOverlay from '../common/loader-overlay';
import { API } from "aws-amplify";

export const ExportButton = ({ resume }) => {
  const options = [
    { label: "PDF With Improving Logo", ImprovingLogo: true},
    { label: "PDF Without Logo", ImprovingLogo: false},
    { label: "Text", rawText: true }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [getResumeById] = useManualQuery(RESUME_BY_ID);

  const handleMenuItemClick = (event, index) => {
    handleExportClick(options[index], event);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleExportClick = async ({ImprovingLogo, rawText}, event) => {
    event.stopPropagation();
    setIsLoading(true);

    try {
      const { data } = await getResumeById({
        variables: {
          resumeId: `${resume.id}`,
        },
      });

      const fileContent = await API.post("generatePdfApi", "/generate/pdf", {
        // For some reason returning the actual binary and using blob produces blank pages
        // so we manually convert the BASE64 data
        responseType: rawText ? "text/plain" : "application/pdf",
        headers: {
          "Content-Type": "application/json",
        },
        body: { data: data.resume_by_pk, ImprovingLogo, rawText },
      }).then(res => {
        if (rawText) {
          return res;
        }

        // Convert BASE64 to a blob.
        const binaryString = window.atob(res);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
      });

      const file = new Blob([fileContent], { type: rawText ? "text/plain" : "application/pdf" });

      // add link to page and click it, to give it a name and auto-download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(file);
      link.download = 'Resume ' + data.resume_by_pk.employee.firstname + ' ' + data.resume_by_pk.employee.lastname + (rawText ? '.txt' : '.pdf');
      document.body.append(link);
      link.click();
      link.remove();
      // remove the data from the page
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    } catch (error) {
      console.log("There was an error exporting the resume.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box position="relative">
      <Box visibility={isLoading ? 'hidden' : 'visible'}>
        <ButtonGroup
          variant="text"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            startIcon={<PublishIcon />}
            endIcon={<ArrowDropDownIcon />}
            aria-controls="export-split-button-menu"
            aria-haspopup="true"
            color="primary"
            onClick={handleToggle}
          >
            Export
          </Button>
        </ButtonGroup>
      </Box>

      <LoaderOverlay showSpinner={isLoading} size="small" />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="export-split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      dense={true}
                      key={option.label}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
